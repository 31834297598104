import { useTranslation } from "react-i18next";
import { Icon } from "components/icon/Icon";
import { useCallback } from "react";
import { FirestoreGenerationStatus } from "__generated__/api";
import { Button } from "components/button";
import { toast } from "utils/toast";
import { logError } from "shared/services/ErrorReporting";
import { useApi } from "hooks/useApi";
import { downloadFile } from "utils/helpers";
import { formatDateTime } from "shared/utils";
import { COMPLETED_STATUSES } from "shared/constants";
import startCase from "lodash/startCase";

interface Evaluation {
  id?: string;
  status?: FirestoreGenerationStatus;
  showStatus?: boolean;
  numberOfBooks: number;
  createdAt?: Date;
}

export const EvaluationCard = ({
  id,
  status,
  showStatus,
  numberOfBooks,
  createdAt,
}: Evaluation) => {
  const { t } = useTranslation(["books"]);
  const api = useApi();

  const handleDownload = useCallback(async () => {
    try {
      if (!id) {
        throw new Error("Evaluation ID not found.");
      }

      // Get the latest evaluation data to ensure we have the most up-to-date file URL
      const response = await api.evaluation.getEvaluation(id);
      if (!response) {
        throw new Error("Download failed. Please try again.");
      }
      const fileUrl = response.file?.url as string;
      const fileName = (response.file?.name as string) || "evaluation.xlsx";

      if (!fileUrl) {
        throw new Error("File URL not found.");
      }
      downloadFile(fileUrl, fileName);
    } catch (error) {
      logError(`Download failed: ${error}`);
      toast.error(t("books:evaluation.downloadError"));
    }
  }, [api.evaluation, id, t]);

  const createdAtStr = createdAt
    ? formatDateTime(createdAt)
    : t("books:evaluation.card.unknownCreatedAt");

  return (
    <div className="shadow-sm border bg-white border-secondary-50 rounded-xl flex items-center justify-between px-6 py-3 space-x-8 h-[90px]">
      <div className="flex items-center space-x-4">
        <div className="bg-secondary-25 rounded-full h-12 w-12 shrink-0 flex items-center justify-center">
          <Icon
            iconClassName="!w-5 !h-5"
            name={
              status && COMPLETED_STATUSES.includes(status)
                ? "chart-area"
                : "loader"
            }
          />
        </div>

        <div className="space-y-0.5">
          <div className="text-secondary-900">{createdAtStr}</div>

          <div className="text-sm text-secondary-400 space-x-2">
            <span className="space-x-1">
              <span>{t("books:evaluation.card.numberOfBooks")}:</span>
              <span>{numberOfBooks}</span>
            </span>

            {status && showStatus && (
              <span className="space-x-1">
                <span>{t("books:evaluation.card.status")}:</span>
                <span>{startCase(status.toLowerCase())}</span>
              </span>
            )}
          </div>
        </div>
      </div>

      {status === FirestoreGenerationStatus.COMPLETED && (
        <Button
          variant="tertiary"
          icon="download"
          onClick={handleDownload}
          className="flex items-center justify-center h-10 w-10 p-0"
          testId="evaluation-card-download"
        />
      )}
    </div>
  );
};
