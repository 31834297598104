import { type ComponentProps, forwardRef } from "react";
import { TableHeader as BaseTableHeader } from "components/ui/table";
import { cn } from "utils";

export const TableHeader = forwardRef<
  HTMLTableSectionElement,
  ComponentProps<typeof BaseTableHeader>
>((props, ref) => {
  return (
    <BaseTableHeader
      ref={ref}
      {...props}
      className={cn("bg-secondary-50", props.className)}
    />
  );
});

TableHeader.displayName = "TableHead";
