import React from "react";
import { Button } from "components/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFixedFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import { useTranslation } from "react-i18next";
import { ModalIcon } from "components/modal/ModalIcon";
import { useDialog } from "hooks/useDialog";
import { useOrganisation } from "contexts/OrganisationProvider";
import { AddOrganisationMemberForm } from "./AddOrganisationMemberForm";

export const AddOrganisationMemberModal = () => {
  const { t } = useTranslation(["settings"]);
  const { organisation } = useOrganisation();

  const isDeletedOrg = !!organisation?.deletedAt;
  const dialog = useDialog("add-organisation-member");

  return (
    <Dialog open={dialog.isOpen} onOpenChange={dialog.onOpenChange}>
      <DialogTrigger asChild>
        <Button
          label={t("settings:organisation.members.add.button")}
          icon="plus"
          className="py-2 font-normal"
          disabled={isDeletedOrg}
        />
      </DialogTrigger>

      <DialogContent className="overflow-visible">
        <DialogHeader className="mb-6">
          <ModalIcon
            icon="user"
            className="bg-primary-100 bg-opacity-75 mb-4 mt-4 ring-8 ring-opacity-5 ring-primary-700"
            pulse={false}
            iconClassName="text-primary-700"
          />

          <DialogTitle className="">
            {t("settings:organisation.members.add.modal.title")}
          </DialogTitle>
          <DialogDescription className="">
            {t("settings:organisation.members.add.modal.description")}
          </DialogDescription>
        </DialogHeader>

        <AddOrganisationMemberForm onClose={() => dialog.close()} />

        <DialogFixedFooter className="flex justify-between gap-2 px-5 items-center">
          <div className="flex justify-end gap-2 ml-auto">
            <DialogClose asChild>
              <Button
                label={t(
                  "settings:organisation.members.add.modal.actions.cancel",
                )}
                variant="tertiary"
                size="small"
              />
            </DialogClose>
            <Button
              type="submit"
              form="add-organisation-members"
              label={t("settings:organisation.members.add.modal.actions.add", {
                count: 1,
              })}
              size="small"
              icon="plus"
            />
          </div>
        </DialogFixedFooter>
      </DialogContent>
    </Dialog>
  );
};
