import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { json2csv } from "csv42";
import { saveAs } from "file-saver";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFixedFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Button } from "components/button";
import { Badge } from "components/ui/badge";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { query, where } from "firebase/firestore";
import { Loader } from "../../../../../../components/loading/Loader";
import { OrganisationOnixUploadFileReportDialogTable } from "./OrganisationOnixUploadFileReportDialogTable";
import {
  getPipelineReportCollection,
  getProductsByIsbns,
} from "../../../../../../integrations/firebase/collections";
import { logError } from "../../../../../../shared/services/ErrorReporting";

interface DownloadProduct {
  isbn: string;
  title?: string;
  author?: string[];
  productGroupDescription?: string;
  publishedAt?: Date | null;
  failed?: boolean;
}

function downloadCSV(products: DownloadProduct[]) {
  try {
    const productsForCSV = products.map((product) => ({
      ISBN: product.isbn,
      Author: product.author?.join(", ") || "",
      Title: product.title || "",
      "Product Form": product.productGroupDescription || "",
      "Published On": product.publishedAt
        ? format(product.publishedAt, "yyyy-MM-dd")
        : "",
      Status: product.failed ? "Failed" : "Success",
    }));

    const csv = json2csv(productsForCSV);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `onix-report-${format(new Date(), "yyyy-MM-dd")}.csv`);
  } catch (error) {
    logError(`Failed to save CSV file: ${error}`);
  }
}

export const OrganisationOnixUploadFileReportDialog = ({
  open,
  onClose,
  fileName,
  organisationId,
}: {
  open: boolean;
  fileName: string | null;
  organisationId: string;
  onClose: () => void;
}) => {
  const { t } = useTranslation(["settings"]);

  const [products, setProducts] = useState<DownloadProduct[]>([]);
  const [loadingProducts, setLoadingProducts] = useState(false);

  const [[report] = [], isLoadingReport] = useCollectionData(
    query(
      getPipelineReportCollection(),
      where("organisation.id", "==", organisationId),
      where("fileName", "==", fileName),
    ),
  );

  useEffect(() => {
    if (!report) {
      return;
    }
    setLoadingProducts(true);
    const isbns = report?.globalIsbns || [];
    getProductsByIsbns(organisationId, isbns).then((isbnProducts) => {
      setProducts(
        isbns.map((isbn, index) => {
          const product = isbnProducts.find((p) => p.isbn === isbn);
          return {
            ...(product || {}),
            identifier: isbns[index],
            isbn: isbns[index],
            failed: (report?.failedIsbns || []).includes(isbn),
            publishedAt: product?.publishedAt?.toDate(),
          };
        }),
      );
      setLoadingProducts(false);
    });
  }, [report, organisationId]);

  const loading = isLoadingReport || loadingProducts;
  const failed = report?.failedIsbns?.length;
  const succeeded = report?.typesenseIsbns?.length;
  const total = report?.productsGlobalCount;

  return (
    <Dialog open={open} onOpenChange={(isOpen) => !isOpen && onClose()}>
      <DialogContent className="overflow-visible min-w-[90%] h-[70vh] flex flex-col">
        <DialogHeader className="items-start space-y-0 my-0 mb-8">
          <DialogTitle className="mb-3 flex items-center">
            {loading ? <Loader className="size-6 mr-3 border-2" /> : null}
            {t("settings:organisation.onix.report.dialog.title")}
          </DialogTitle>
          <DialogDescription className="text-left" asChild>
            <div>
              <span className="text-secondary-400 uppercase mr-2">
                {t("settings:organisation.onix.report.dialog.subtitle")}
              </span>
              {total ? (
                <span className="text-secondary-500">
                  {t("settings:organisation.onix.misc.books", { count: total })}
                </span>
              ) : null}
              {failed ? (
                <>
                  <span className="text-secondary-300 mx-2">|</span>
                  <span className="text-secondary-500">
                    {t("settings:organisation.onix.misc.books", {
                      count: failed,
                    })}
                  </span>
                  <Badge variant="error" className="ml-2">
                    {t("settings:organisation.onix.misc.badge.failed")}
                  </Badge>
                </>
              ) : null}
              {succeeded ? (
                <>
                  <span className="text-secondary-300 mx-2">|</span>
                  <span className="text-secondary-500">
                    {t("settings:organisation.onix.misc.books", {
                      count: succeeded,
                    })}
                  </span>
                  <Badge variant="success" className="ml-2">
                    {t("settings:organisation.onix.misc.badge.success")}
                  </Badge>
                </>
              ) : null}
            </div>
          </DialogDescription>
        </DialogHeader>

        <div className="flex-1 overflow-y-auto">
          <OrganisationOnixUploadFileReportDialogTable
            products={products}
            loading={loading}
          />
        </div>

        <DialogFixedFooter className="flex justify-between gap-2 px-5 items-center">
          <div className="flex justify-end gap-2 ml-auto">
            <Button
              label={t("settings:organisation.onix.report.dialog.cancel")}
              onClick={onClose}
              variant="tertiary"
              size="small"
            />
            <Button
              label={t("settings:organisation.onix.report.dialog.export")}
              size="small"
              onClick={() => downloadCSV(products)}
              disabled={loading || products?.length === 0}
            />
          </div>
        </DialogFixedFooter>
      </DialogContent>
    </Dialog>
  );
};
