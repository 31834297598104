import {
  cancelEvaluation,
  getEvaluation,
  postEvaluation,
} from "reedy-data/api";
import { BaseApi } from "./base";

export class EvaluationApi extends BaseApi {
  async evaluate(ids: string[] | "ALL") {
    const response = await this.request(postEvaluation, {
      body: { requestedProducts: ids },
    });

    return response?.data;
  }

  async getEvaluation(id: string) {
    const response = await this.request(getEvaluation, {
      path: { identifier: id },
    });

    return response?.data;
  }

  async cancel(id: string) {
    const response = await this.request(cancelEvaluation, {
      path: { identifier: id },
    });

    return response?.data;
  }
}
