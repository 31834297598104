import { useTranslation } from "react-i18next";
import { Input } from "components/ui/input";
import { useFormContext } from "react-hook-form";
import { ComparisonOperatorDropdown } from "./ComparisonOperatorDropdown";

interface NumericFilterProps {
  error: string | undefined;
  name: string;
}

export const NumericFilter = ({ error, name }: NumericFilterProps) => {
  const { t } = useTranslation(["general"]);
  const { register } = useFormContext();
  return (
    <span className="flex flex-row focus-within:outline focus-within:outline-1 focus-within:outline-primary focus-within:ring focus-within:ring-offset-0 focus-within:ring-primary-100 rounded">
      <ComparisonOperatorDropdown name={`filters.${name}.comparisonOperator`} />

      <Input
        type="number"
        min={0}
        placeholder={t("general:filters.numericPlaceholder")}
        className="rounded rounded-l-none  text-sm w-[90px] text-left outline-none shadow-none"
        aria-label={`${name} input`}
        aria-invalid={!!error}
        {...register(`filters.${name}.value`, { valueAsNumber: true })}
      />
      {error && <p className="text-red-500 text-xs">{error}</p>}
    </span>
  );
};
