import { PropsWithChildren, Ref } from "react";

interface ButtonGroupProps extends PropsWithChildren {
  ref?: Ref<HTMLDivElement>;
}

export const ButtonGroup = ({ children, ref }: ButtonGroupProps) => {
  return (
    <div
      ref={ref}
      className="flex [&_button]:rounded-none [&_button]:border-r-0 [&_button:last-of-type]:border-r [&_button:first-of-type]:rounded-l-lg [&_button:last-of-type]:rounded-r-lg"
    >
      {children}
    </div>
  );
};
