import { Button } from "components/button/Button";
import { Icon } from "components/icon/Icon";
import { Info } from "components/icon/Info";
import { Card, CardContent } from "components/ui/card";
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
  Dialog,
  DialogHeader,
} from "components/ui/dialog";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";

export const UploadManuscript = () => {
  const { t } = useTranslation(["books"]);

  const handleUploadManuscript = () => {
    toast.success(t("books:uploadManuscript.toasts.success"));
  };

  return (
    <div className="space-y-4">
      <h2 className="text-neutral-700 text-xl font-semibold">
        {t("books:uploadManuscript.title")}
      </h2>

      <Card className="bg-secondary-25">
        <CardContent className="flex flex-col items-center justify-center py-8 space-y-4">
          <Icon size="large" name="upload" iconClassName="text-primary-600" />
          <div className="max-w-lg flex flex-col items-center text-center gap-2">
            <span className="text-secondary-800">
              {t("books:uploadManuscript.subtitle")}
            </span>
            <span className=" text-secondary-400 space-y">
              {t("books:uploadManuscript.description")}
            </span>
          </div>
          <Dialog>
            <DialogTrigger asChild>
              <Button
                icon="upload"
                variant="ghost"
                analyticsId="button_upload_manuscript"
                label={t("books:uploadManuscript.button.upload")}
              />
            </DialogTrigger>
            <DialogContent className="max-w-lg">
              <DialogHeader className="space-y-3 py-2 -mt-4">
                <Info icon="loader" />
                <DialogTitle>
                  {t("books:uploadManuscript.dialog.title")}
                </DialogTitle>
                <DialogDescription className="text-sm">
                  {t("books:uploadManuscript.dialog.description")}
                </DialogDescription>
              </DialogHeader>

              <DialogFooter>
                <DialogClose asChild>
                  <Button
                    variant="tertiary"
                    size="small"
                    className="w-full"
                    label={t("general:buttons.close")}
                    analyticsId="button_upload_manuscript_close"
                  />
                </DialogClose>
                <DialogClose asChild>
                  <Button
                    variant="primary"
                    size="small"
                    onClick={handleUploadManuscript}
                    className="w-full"
                    analyticsId="button_upload_manuscript_yippee"
                    label={t("books:uploadManuscript.button.yippee")}
                  />
                </DialogClose>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </CardContent>
      </Card>
    </div>
  );
};
