import { type ColumnDef } from "components/table";
import { Timestamp } from "firebase/firestore";
import { DataTableColumnHeader } from "components/ui/data-table-column-header";
import { IProduct } from "integrations/firebase/collections";
import { TFunction } from "i18next";
import { TableStatusBadges } from "components/GenerationStatusBadge/TableStatusBadges";
import { Checkbox } from "../../../components/ui/checkbox";

export const columns: (t: TFunction) => ColumnDef<IProduct>[] = (t) => [
  {
    id: "select",
    ignoreColumnClick: true,
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && "indeterminate")
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
        className="translate-y-[2px]"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label={`Select row ${row.original.id}`}
        disabled={row.original.nonExistent}
        className="translate-y-[2px]"
      />
    ),
  },
  {
    accessorKey: "isbn",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("projectDetails:table.columns.isbn")}
      />
    ),
    cell: ({ row }) => {
      return (
        <div
          className={`${row.original.nonExistent ? "text-system-error" : ""}`}
        >
          {row.getValue("isbn") || row.original.id}
        </div>
      );
    },
  },
  {
    accessorKey: "author",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("projectDetails:table.columns.author")}
      />
    ),
    cell: ({ row }) => {
      const authors = row.getValue("author") as string[];

      return <div className="flex space-x-2">{authors?.join(", ")}</div>;
    },
  },
  {
    accessorKey: "title",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("projectDetails:table.columns.title")}
      />
    ),
    cell: ({ row }) => (
      <div className="flex space-x-2">{row.getValue("title")}</div>
    ),
  },
  {
    accessorKey: "productGroupDescription",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("projectDetails:table.columns.productForm")}
      />
    ),
    cell: ({ row }) => (
      <div className="flex items-center">
        <span> {row.getValue("productGroupDescription")}</span>
      </div>
    ),
  },
  {
    accessorKey: "updatedAt",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("projectDetails:table.columns.updatedOn")}
      />
    ),
    cell: ({ row }) => {
      const publishedAt = row.getValue("updatedAt") as Timestamp;
      return (
        <div className="flex items-center">
          <span>
            {publishedAt?.toDate &&
              publishedAt?.toDate()?.toISOString().slice(0, 10)}
          </span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "generated",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("projectDetails:table.columns.generated")}
        className="items-center justify-center"
      />
    ),
    cell: ({ row }) => {
      return <TableStatusBadges row={row} />;
    },
  },
];
