import { useTranslation } from "react-i18next";
import { Button } from "components/button";
import { ButtonCopyTextToClipboard } from "components/button/ButtonCopyTextToClipboard";
import { useProduct } from "contexts/ProductContext";
import { useBookDescription } from "contexts/BookDescriptionContext";
import { BasePanel } from "components/BasePanel";
import { actionErrored, actionInProgress } from "utils/status";
import { getDescription } from "reedy-data";
import { EmptyText } from "components/empty/EmptyText";
import { OptimiseDescriptionModal } from "../Details/OptimiseDescriptionModal";
import {
  getKeywordsByType,
  keywordsToText,
  getDescriptionActions,
} from "../../../../../../integrations/firebase/collections";
import { GeneratedDescriptionEditor } from "./GeneratedDescriptionEditor";

export const GenerateDescription = () => {
  const { t } = useTranslation(["productDetails", "general"]);
  const { product } = useProduct();
  const {
    selectedDescriptionHash,
    handleCopyDoneGeneratedBookDescription,
    onUpdateGeneratedDescription,
    setShowDetailsGenerateBookDescription,
  } = useBookDescription();

  const selectedDescription = getDescription(product, selectedDescriptionHash);
  const generatedDescriptionText = selectedDescription?.textGenerated;
  const actions = getDescriptionActions(product, selectedDescription?.hash);
  const { generated } = getKeywordsByType(product);
  const keywords = { generated: keywordsToText(generated) };
  const generating = actionInProgress(actions?.generate);
  const error =
    actions?.generate && actionErrored(actions?.generate)
      ? {
          statusCode: actions.generate.statusCode,
          message: actions.generate.msg,
        }
      : undefined;

  const isEmpty = !generatedDescriptionText && !error && !generating;

  const canReOptimise =
    generatedDescriptionText &&
    !actionErrored(actions?.generate) &&
    !actionInProgress(actions?.generate);

  return (
    <>
      <BasePanel
        title={t(
          "productDetails:bookDescription.tabGenerate.generatedDescription",
        )}
        className="h-[var(--subject-list-container-height)]"
        wrapper={false}
        headerAction={
          canReOptimise && (
            <div className="flex gap-2">
              <Button
                label={t("general:buttons.regenerate")}
                variant="ghost"
                icon="loader"
                className="px-2"
                onClick={() => setShowDetailsGenerateBookDescription(true)}
              />
              <ButtonCopyTextToClipboard
                textToCopy={generatedDescriptionText || ""}
                tooltipText={t("general:tooltips.copyToClipboard")}
                onDone={handleCopyDoneGeneratedBookDescription}
                buttonText={t("general:buttons.copy")}
              />
            </div>
          )
        }
        headerClassName="bg-primary-50"
        border={false}
        isEmpty={isEmpty}
        emptyComponent={
          <EmptyText
            title={t("productDetails:bookDescription.tabGenerate.empty.header")}
            text={
              selectedDescription?.text
                ? t("productDetails:bookDescription.tabGenerate.empty.message")
                : t("productDetails:bookDescription.tabGenerate.empty.disabled")
            }
            variant="primary"
            buttons={[
              <Button
                key="button_generate_book_description"
                variant="primary"
                icon="loader"
                label={t("general:buttons.generate")}
                disabled={!selectedDescription?.text}
                onClick={() => setShowDetailsGenerateBookDescription(true)}
                size="small"
                analyticsId="button_generate_book_description"
                testId="button_generate_book_description"
              />,
            ]}
          />
        }
      >
        {selectedDescription && (
          <GeneratedDescriptionEditor
            description={selectedDescription}
            keywords={keywords}
            loading={generating}
            error={error}
            className="h-[calc(100%-48px)]"
            onUpdate={onUpdateGeneratedDescription}
            onRetry={() => setShowDetailsGenerateBookDescription(true)}
          />
        )}
      </BasePanel>
      <OptimiseDescriptionModal />
    </>
  );
};
