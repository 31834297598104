import LoadingSpinner from "components/loading/LoadingSpinner";

import { useSearch } from "hooks/search/useSearch";
import { useEffect, useState } from "react";

import { useFormContext } from "react-hook-form";
import { SearchInput } from "components/input/SearchInput";
import { useFilters } from "hooks/search/useFilters";
import { useTranslation } from "react-i18next";
import { CheckboxSection } from "./CheckboxSection";

interface FacetCount {
  counts: { count: number; value: string }[];
  field_value: string;
}

export const MultiSelectFilter = ({ name }: { name: string }) => {
  const { register, watch } = useFormContext();
  const [items, setItems] = useState<string[]>([]);
  const searchInput = watch(`filters.${name}.search`);
  const { filters } = useFilters();
  const { t } = useTranslation(["books"]);
  const selectedFormItems = watch(`filters.${name}.values`);
  const { data: searchData, isLoading: isLoadingSearch } = useSearch(
    {
      query: "*",
      query_by: name,
      facet_by: name,
      facet_query: searchInput ? `${name}:${searchInput}` : undefined,
      pageSize: 0,
    },
    { debounce: 250 },
  );

  useEffect(() => {
    const facetCounts = searchData?.facet_counts as FacetCount[] | undefined;
    const counts = facetCounts?.[0]?.counts;
    if (counts) {
      setItems(counts.map((item) => item.value));
    }
  }, [searchData, name]);

  const suggestions = items.filter(
    (item) => !selectedFormItems?.includes(item),
  );

  return (
    <div className="flex flex-col gap-y-2">
      <SearchInput
        className="m-2 w-[calc(100%-1rem)]"
        placeholder={
          filters.find((filter) => filter.name === name)?.placeholder
        }
        {...register(`filters.${name}.search`)}
      />
      <CheckboxSection
        items={selectedFormItems}
        name={name}
        label={t(`books:filters.multiSelect.selected`)}
      />
      {isLoadingSearch && (
        <div className="flex w-full justify-center py-2 px-6 text-sm">
          <LoadingSpinner size="24px" />
        </div>
      )}
      {!isLoadingSearch && (
        <CheckboxSection
          items={suggestions}
          name={name}
          label={t(`books:filters.multiSelect.suggestions`)}
          noSuggestionsFound={
            !isLoadingSearch && !suggestions.length
              ? filters.find((filter) => filter.name === name)?.noResultLabel
              : undefined
          }
        />
      )}
    </div>
  );
};
