import { SkeletonKeyword, SkeletonKeywordVariant } from "./SkeletonListItem";

export interface SkeletonListProps {
  variant?: SkeletonKeywordVariant;
}

export const SkeletonList = ({ variant = "primary" }: SkeletonListProps) => {
  return (
    <div className="flex justify-center flex-col">
      <SkeletonKeyword
        animated={false}
        className="scale-75 relative animate-none opacity-60"
      />
      <SkeletonKeyword
        animated={false}
        variant={variant}
        className="scale-75 relative animate-none"
      />
      <SkeletonKeyword
        animated={false}
        className="scale-75 relative animate-none opacity-60"
      />
    </div>
  );
};
