import { flexRender } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import {
  TableBody,
  TableHead,
  TableHeader,
  Table,
  TableRow,
} from "components/table";
import { useProjects } from "pages/Projects/contexts/ProjectsContext";
import { useTable } from "hooks/useTable";
import { useCallback, useEffect, useMemo } from "react";
import { ProjectsTableBody } from "components/projectsTable/ProjectsTableBody";
import { Button } from "components/button";
import { Link, useNavigate } from "react-router-dom";
import { IProject } from "integrations/firebase/collections";
import { RoutePath } from "shared/constants";
import { useAnalytics } from "contexts/AnalyticsContext";
import { columns as getColumns } from "./columns";

export const RecentProjects = () => {
  const { projects, isLoadingRead, errorRead, count } = useProjects();
  const { setShowOnlyMyProjects } = useProjects();
  const { gaEvent } = useAnalytics();
  const navigate = useNavigate();

  const data = useMemo(() => projects.slice(0, 3), [projects]);

  useEffect(() => {
    setShowOnlyMyProjects(true);
  }, [setShowOnlyMyProjects]);

  const { t } = useTranslation(["components"]);

  const columns = useMemo(() => getColumns(t), [t]);

  const { table } = useTable({
    columns,
    data,
    totalRecords: count || 0,
  });

  const handleRowClicked = useCallback(
    (row: IProject) => {
      navigate(RoutePath.ProjectDetails.replace(":idProject", row.id!));
      gaEvent({
        type: "view_item_list",
        payload: {
          item_list_name: row.name,
          item_list_id: row.id,
          item_category: "project",
        },
      });
    },
    [navigate, gaEvent],
  );

  return (
    <div className="space-y-4 text-left">
      <div className="flex flex-row justify-between items-center w-full">
        <h2 className="text-neutral-700 text-xl font-semibold">
          {t("components:table.description")}
        </h2>

        <Link to="/projects">
          <Button
            variant="link"
            className="text-primary -my-4 hover:text-primary-800"
            iconPosition="right"
            icon="arrow-right"
            label={t("components:table.allProjects")}
          />
        </Link>
      </div>

      <Table variant="shadowed">
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          <ProjectsTableBody
            isLoadingRead={isLoadingRead}
            errorRead={errorRead}
            projects={projects}
            table={table}
            columns={columns}
            onRowClicked={handleRowClicked}
          />
        </TableBody>
      </Table>
    </div>
  );
};
