import { useState } from "react";
import { Button } from "components/button";
import { useTranslation } from "react-i18next";
import { useDialog } from "hooks/useDialog";
import { useOrganisation } from "contexts/OrganisationProvider";
import { toast } from "utils/toast";
import { Modal } from "components/modal/Modal";
import { useAuth } from "contexts/AuthContext";
import { Tooltip, TooltipContent, TooltipTrigger } from "components/ui/tooltip";
import { useApi } from "hooks/useApi";
import { logError } from "shared/services/ErrorReporting";

export const SwitchOrganisationModal = () => {
  const { t } = useTranslation(["settings"]);
  const api = useApi();
  const [isSwitching, setIsSwitching] = useState(false);
  const dialog = useDialog("switch-organisation");
  const { organisation } = useOrganisation();
  const { userData } = useAuth();

  const isCurrentOrg = userData?.organisation?.id === organisation?.id;
  const isDeletedOrg = !!organisation?.deletedAt;
  const isDisabled = isCurrentOrg || isDeletedOrg;

  async function onConfirm() {
    if (isSwitching || !organisation || !userData) {
      return;
    }

    setIsSwitching(true);

    try {
      await api.account.organisations.transferUser(
        userData.organisation.id,
        organisation.id,
        userData.id,
      );

      toast.success(t("settings:organisation.switch.success"));
      dialog.close();
    } catch (e) {
      logError(e);
      toast.error(t("settings:organisation.switch.error"));
    }

    setIsSwitching(false);
  }

  return (
    <>
      <Tooltip open={isCurrentOrg ? undefined : false}>
        <TooltipTrigger asChild>
          <span>
            <Button
              variant="tertiary"
              onClick={dialog.open}
              className="mx-4"
              icon="user-plus"
              disabled={isDisabled}
            >
              {t("settings:organisation.switch.button")}
            </Button>
          </span>
        </TooltipTrigger>
        <TooltipContent>
          <p>{t("settings:organisation.switch.tooltip")}</p>
        </TooltipContent>
      </Tooltip>

      <Modal
        id={dialog.id!}
        open={dialog.isOpen}
        variant="warning"
        mainIconName="user-plus"
        title={t("settings:organisation.switch.title")}
        description={t("settings:organisation.switch.description", {
          name: organisation?.name,
        })}
        confirmLabel={t("settings:organisation.switch.actions.confirm")}
        cancelLabel={t("settings:organisation.switch.actions.cancel")}
        onOpenChange={dialog.onOpenChange}
        loading={isSwitching}
        onConfirm={() => onConfirm()}
      />
    </>
  );
};
