import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ContentCard } from "components/ContentCard/ContentCard";

export const OrganisationSettingsPage = () => {
  const { t } = useTranslation("settings");
  const navigate = useNavigate();

  return (
    <div className="space-y-10">
      <section>
        <h2 className="text-2xl font-semibold mb-2">
          {t("organisation.settings.globalFilters.title")}
        </h2>
        <p className="text-sm text-secondary-400 pb-4">
          {t("organisation.settings.globalFilters.description")}
        </p>

        <div className="space-y-4">
          <ContentCard
            title={t("organisation.settings.productAvailability.title")}
            iconName="truck"
            actionIconName="chevron-right"
            onClick={() => navigate("product-availability")}
            actionTestId="product-availability-action"
            button
          />
        </div>
      </section>
    </div>
  );
};
