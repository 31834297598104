import LoadingFrame1 from "components/svg/loading/LoadingFrame1";
import { useTranslation } from "react-i18next";

export const LoadingSpinner = ({ size }: { size: string }) => {
  const { t } = useTranslation(["general"]);
  return (
    <div
      className={`flex flex-col items-center w-[${size}] h-[${size}] animate-spin`}
      aria-label={t("general:loading")}
    >
      <LoadingFrame1 size={size} />
    </div>
  );
};

export default LoadingSpinner;
