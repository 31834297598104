import { useTranslation } from "react-i18next";
import { BasePanel } from "components/BasePanel";
import { EmptyList } from "components/empty/EmptyList";
import { Button } from "components/button/Button";
import { FirestoreGenerationStatusCode } from "../../../../__generated__/models";
import { generationErrorMessage } from "../../utils";

export const KeywordsError = ({
  statusCode,
  onGenerate,
}: {
  statusCode?: FirestoreGenerationStatusCode | null;
  onGenerate: () => void;
}) => {
  const { t } = useTranslation(["productDetails"]);

  return (
    <BasePanel
      title={t("productDetails:generatedKeywords.header")}
      isEmpty
      emptyComponent={
        <EmptyList
          title={t("productDetails:generatedKeywords.error.header")}
          errorMessage={
            generationErrorMessage(t, statusCode) ||
            t("productDetails:generatedKeywords.error.message", {
              code: statusCode,
            })
          }
          variant="error"
          buttons={[
            <Button
              key="generate"
              label={t("general:buttons.retry")}
              onClick={onGenerate}
              icon="loader"
              size="small"
              analyticsId="button_regenerate_keywords"
            />,
          ]}
        />
      }
    />
  );
};
