import { type ColumnDef } from "components/table";
import { TFunction } from "i18next";
import { DataTableColumnHeader } from "components/ui/data-table-column-header";
import { FirestoreOrganisation } from "__generated__/models";
import { cn } from "utils/cn";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";

import { Icon } from "components/icon/Icon";
import { OrganisationName } from "./OrganisationName";
import { DeleteOrganisationModal } from "./DeleteOrganisationModal";
import { UndeleteOrganisationModal } from "./UndeleteOrganisationModal";

export const columns: (t: TFunction) => ColumnDef<FirestoreOrganisation>[] = (
  t,
) => [
  {
    accessorKey: "name",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("settings:organisations.overview.table.columns.name")}
      />
    ),
    cell: ({ row }) => (
      <div
        className={cn("w-[40%]", !!row.original.deletedAt && "text-red-300")}
      >
        <OrganisationName
          id={row.id}
          isEditing={row.getIsEditing()}
          name={row.getValue("name")}
          onChange={() => row.toggleEditing(false)}
        />
        <div className="text-red-200 font-medium italic">
          {row.original.deletedAt && !row.original.hardDeletedAt
            ? t("settings:organisations.overview.table.name.deleted", {
                date: row.original.deletedAt.toLocaleDateString(),
              })
            : null}
          {row.original.hardDeletedAt
            ? t("settings:organisations.overview.table.name.hardDeleted", {
                date: row.original.hardDeletedAt.toLocaleDateString(),
              })
            : null}
        </div>
      </div>
    ),
  },
  {
    accessorKey: "menu",
    ignoreColumnClick: true,
    header: ({ column }) => <DataTableColumnHeader column={column} title="" />,
    cell: ({ row }) => {
      return (
        <div className="justify-end flex items-center">
          <DropdownMenu>
            <DropdownMenuTrigger
              className="invisible group-hover:visible focus:visible text-secondary-500 p-2"
              data-testid="org-menu-trigger"
            >
              <Icon name="ellipsis-vertical" />
            </DropdownMenuTrigger>
            <DropdownMenuContent
              side="left"
              className="rounded-xl"
              align="start"
            >
              <DropdownMenuGroup>
                {!row.original.deletedAt && (
                  <DropdownMenuItem asChild>
                    <button
                      type="button"
                      onClick={() => row.toggleEditing(true)}
                      className="flex items-center gap-2 w-full justify-start disabled:opacity-50 disabled:cursor-not-allowed"
                      aria-label={t(
                        "settings:organisations.overview.menu.edit",
                      )}
                      disabled
                    >
                      <Icon name="square-pen" />
                      <span className="text-sm text-secondary-500">
                        {t("settings:organisations.overview.menu.edit")}
                      </span>
                    </button>
                  </DropdownMenuItem>
                )}
                {!row.original.deletedAt && (
                  <DropdownMenuItem asChild>
                    <DeleteOrganisationModal
                      organisationId={row.original.id}
                      organisationName={row.original.name}
                    />
                  </DropdownMenuItem>
                )}
                {row.original.deletedAt && (
                  <DropdownMenuItem asChild>
                    <UndeleteOrganisationModal
                      organisationId={row.original.id}
                      organisationName={row.original.name}
                    />
                  </DropdownMenuItem>
                )}
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      );
    },
  },
];
