import { SkeletonTextVariant } from "components/skeletons/SkeletonText";
import { SkeletonList } from "components/skeletons/SkeletonList";
import { EmptyPanel } from "./EmptyPanel";

export interface EmptyListProps {
  title: string;
  text?: string;
  image?: React.ReactNode;
  errorMessage?: string;
  variant?: SkeletonTextVariant;
  orientation?: "vertical" | "horizontal";
  buttons?: React.ReactNode[];
  hideOnShortScreen?: boolean;
}

export const EmptyList = ({
  title,
  text,
  image,
  errorMessage,
  variant,
  buttons,
  orientation = "vertical",
  hideOnShortScreen = false,
}: EmptyListProps) => {
  return (
    <EmptyPanel
      image={image || <SkeletonList variant={variant} />}
      title={title}
      text={text}
      errorMessage={errorMessage}
      buttons={buttons}
      orientation={orientation}
      hideOnShortScreen={hideOnShortScreen}
    />
  );
};
