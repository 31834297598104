import { useCallback, useEffect, useState } from "react";
import { useApi } from "hooks/useApi";
import { useAnalytics } from "contexts/AnalyticsContext";

import { IProduct } from "integrations/firebase/collections";
import { OnixGenerationModalStatus } from "pages/ProjectDetails/types/types";
import { OnixExportStatus } from "reedy-data/models";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { getOnixExportDocument } from "../integrations/firebase/collections/onixExports";
import { logError } from "../shared/services/ErrorReporting";

export const useOnixGeneration = () => {
  const api = useApi();
  const { gaEvent } = useAnalytics();
  const [showOnixExportModal, setShowOnixExportModal] = useState(false);
  const [exportId, setExportId] = useState<string | null>(null);
  const [onixUrl, setOnixUrl] = useState<string | null>(null);
  const [generatingStatus, setGeneratingStatus] =
    useState<OnixGenerationModalStatus>(OnixGenerationModalStatus.IDLE);

  const [onixExport, , error] = useDocumentData(
    exportId ? getOnixExportDocument(exportId) : null,
  );

  useEffect(() => {
    if (onixExport?.status === OnixExportStatus.COMPLETED) {
      if (onixExport.fileName) {
        setGeneratingStatus(OnixGenerationModalStatus.SUCCESS);
        setOnixUrl(onixExport.fileName.split("/").pop() || "");
      } else {
        setGeneratingStatus(OnixGenerationModalStatus.ERROR);
      }
    }
    if (onixExport?.status === OnixExportStatus.ERROR) {
      setGeneratingStatus(OnixGenerationModalStatus.ERROR);
    }
    if (error) {
      setGeneratingStatus(OnixGenerationModalStatus.ERROR);
    }
  }, [onixExport, error]);

  const handleOnixExportButtonClick = useCallback(
    async (selectedProducts: IProduct[]) => {
      setOnixUrl(null);
      setGeneratingStatus(OnixGenerationModalStatus.REQUESTED);
      setShowOnixExportModal(true);
      gaEvent({
        type: "export_books",
        payload: { books_count: selectedProducts.length, export_type: "onix" },
      });

      try {
        const result = await api.products.exportProducts(
          selectedProducts.map((p) => p.id),
        );
        if (result?.status !== OnixExportStatus.REQUESTED || !result?.id) {
          setGeneratingStatus(OnixGenerationModalStatus.ERROR);
        } else {
          setExportId(result.id);
        }
      } catch (err) {
        logError(err);
        setGeneratingStatus(OnixGenerationModalStatus.ERROR);
      }
    },
    [api.products, gaEvent],
  );

  return {
    showOnixExportModal,
    setShowOnixExportModal,
    handleOnixExportButtonClick,
    generatingStatus,
    setGeneratingStatus,
    onixUrl,
  };
};
