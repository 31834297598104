import { useTranslation } from "react-i18next";
import { format, differenceInMinutes } from "date-fns";
import OnixFileIcon from "assets/svg/onix-file-icon.svg?react";
import { Button } from "components/button";
import { Badge } from "components/ui/badge";
import { PipelineImportReport } from "reedy-data/models";
import { Loader } from "components/loading/Loader";
import { Progress } from "components/ui/progress";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { query, where } from "firebase/firestore";
import React from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../../../../components/ui/tooltip";

import { Error } from "../../../../../../components/icon/Error";
import { getPipelineReportCollection } from "../../../../../../integrations/firebase/collections";

const getProgress = (report?: PipelineImportReport) => {
  if (!report || !report.completedAt) {
    return 0;
  }

  const total = report.productsGlobalCount;
  const failed = report.productsFailedCount;
  const imported = report.typesenseIsbns?.length || 0;

  if (total === failed) {
    return 1;
  }

  return imported / (total - failed) || 0;
};

export const OrganisationOnixUploadFile = ({
  fileName,
  fileType = "Onix",
  organisationId,
  fileSizeMB,
  createdAt,
  onActionClick,
  dummy,
}: {
  fileName: string;
  fileType?: string;
  organisationId: string;
  fileSizeMB?: number;
  createdAt?: Date | null;
  onActionClick?: () => void;
  dummy?: boolean;
}) => {
  const { t } = useTranslation(["settings"]);

  const [[report] = [], loading] = useCollectionData(
    query(
      getPipelineReportCollection(),
      where("organisation.id", "==", organisationId),
      where("fileName", "==", fileName),
    ),
  );

  const failed = report?.failedIsbns?.length;
  const succeeded = report?.typesenseIsbns?.length;
  const progress = getProgress(report);
  const state = progress === 1 ? "success" : "processing";

  // Add logic when the report is stalled
  const isTimedOut = createdAt
    ? differenceInMinutes(new Date(), createdAt) > 5 && progress < 1
    : false;
  const isNotFound = !loading && !dummy && !report && isTimedOut;

  const isLoading = loading || dummy || progress === 0;

  return (
    <li className="flex border-secondary-200 border rounded-xl p-3 justify-between items-center">
      <div className="flex gap-3">
        <div className="border-secondary-200 border rounded-lg size-11 flex items-center justify-center">
          <OnixFileIcon />
        </div>
        <div className="flex flex-col justify-center">
          <p className="text-sm font-medium">{fileName}</p>
          <p className="text-xs text-secondary-400 mt-1">
            {fileType} &middot;{" "}
            {fileSizeMB ? `${fileSizeMB.toFixed(2)} MB ·` : ""}{" "}
            {format(createdAt || new Date(), "P")}
          </p>
        </div>
      </div>
      <div className="flex gap-3 items-center">
        {failed ? (
          <div className="text-xs flex whitespace-nowrap items-center">
            {t("settings:organisation.onix.misc.books", { count: failed })}
            <Badge variant="error" className="ml-2">
              {t("settings:organisation.onix.misc.badge.failed")}
            </Badge>
          </div>
        ) : null}
        {succeeded ? (
          <div className="text-xs flex whitespace-nowrap items-center">
            {t("settings:organisation.onix.misc.books", {
              count: succeeded,
            })}
            <Badge variant="success" className="ml-2">
              {t("settings:organisation.onix.misc.badge.success")}
            </Badge>
          </div>
        ) : null}
        {isNotFound || isTimedOut ? (
          <Tooltip>
            <TooltipTrigger>
              <Error pulse={false} iconSize="small" />
            </TooltipTrigger>
            <TooltipContent>
              <div className="flex flex-col items-start">
                {t("settings:organisation.onix.misc.error")}
              </div>
            </TooltipContent>
          </Tooltip>
        ) : null}
        {state === "success" ? (
          <Button
            variant="tertiary"
            size="small"
            icon="move-up-right"
            iconPosition="right"
            onClick={onActionClick}
          >
            {t("settings:organisation.onix.file.button.report")}
          </Button>
        ) : null}
        {isLoading && !isTimedOut ? (
          <Loader className="size-6 mr-2 border-2" />
        ) : null}
        {state === "processing" && !isLoading && !isTimedOut ? (
          <Progress
            value={progress * 100}
            className="w-48 h-2 bg-secondary-200"
          />
        ) : null}
      </div>
    </li>
  );
};
