import { flexRender, Table } from "@tanstack/react-table";
import { type ColumnDef, TableCell, TableRow } from "components/table";
import { IProduct } from "integrations/firebase/collections";
import { useTranslation } from "react-i18next";
import { SkeletonRows } from "./SkeletonRows";

interface BooksTableBodyProps {
  table: Table<IProduct>;
  onRowClick: (product: IProduct) => void;
  columns: ColumnDef<IProduct>[];
  loading: boolean;
}

export const BooksTableBody = ({
  table,
  columns,
  loading,
  onRowClick,
}: BooksTableBodyProps) => {
  const { t } = useTranslation("projects");

  if (loading) {
    return <SkeletonRows columns={columns} />;
  }

  const { rows } = table.getRowModel();

  if (rows?.length) {
    return (
      <>
        {rows.map((row) => (
          <TableRow
            key={row.id}
            data-testid={`book-${row.id}`}
            data-book-id={row.id}
            data-state={row.getIsSelected() && "selected"}
            onClick={() => {
              if (row.original.nonExistent) {
                return;
              }

              onRowClick(row.original);
            }}
          >
            {row.getVisibleCells().map((cell) => (
              <TableCell
                id={cell.id}
                columnDef={cell.column.columnDef}
                key={cell.id}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </>
    );
  }

  return (
    <TableRow>
      <TableCell colSpan={columns.length} className="h-24 text-center">
        {t("books:booktable.noResults")}
      </TableCell>
    </TableRow>
  );
};

BooksTableBody.displayName = "BooksTableBody";
