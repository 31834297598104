import { Timestamp } from "firebase/firestore";
import { FirestoreProduct } from "./__generated__/models";

export type Model<T> = {
  [P in keyof T]: T[P] extends Date
    ? Timestamp
    : T[P] extends Date | undefined
      ? Timestamp | undefined
      : T[P] extends Date | null
        ? Timestamp | null
        : T[P];
};

export type IProduct = Model<FirestoreProduct> & {
  id: string;
  isbn: string;
  nonExistent?: boolean;
};

export const getDescription = (product?: IProduct, hash?: string) => {
  if (!product) {
    return undefined;
  }
  if (!hash) {
    return product.descriptions?.find((desc) => desc.default);
  }

  return product.descriptions?.find((desc) => desc.hash === hash);
};

export const getDefaultDescription = (product?: IProduct) => {
  return product?.descriptions?.find((desc) => desc.default);
};

export const getAllDescriptionActions = (product?: IProduct) => {
  return product?.actions?.descriptions?.actions;
};

export const getDescriptionActions = (product?: IProduct, hash?: string) => {
  if (!product || !hash) {
    return undefined;
  }

  return product.actions?.descriptions?.actions?.[hash];
};

export const getDefaultDescriptionActions = (product?: IProduct) => {
  return getDescriptionActions(product, getDefaultDescription(product)?.hash);
};
