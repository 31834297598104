import Google from "components/svg/icons/Google";
import Amazon from "components/svg/icons/Amazon";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { useTrends } from "contexts/TrendsContext";
import { TrendsType } from "reedy-data/models";
import { capitalizeFirstLetter } from "utils/helpers";

const icons = {
  [TrendsType.GOOGLE]: <Google />,
  [TrendsType.AMAZON]: <Amazon />,
};
export const SourceSelect = () => {
  const { trendTypes, setSelectedTrendsType, selectedTrendsType } = useTrends();

  return (
    <Select onValueChange={setSelectedTrendsType} value={selectedTrendsType}>
      <SelectTrigger testId="trends-type-select" className="w-[180px]">
        <SelectValue placeholder="Trend type" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {trendTypes.map((type) => (
            <SelectItem
              key={type}
              value={type}
              testId={`trend-menu-item-${type.toLowerCase()}`}
            >
              <div className="flex flex-row gap-2 items-center">
                {icons[type]}
                <p className="text-sm">{capitalizeFirstLetter(type)}</p>
              </div>
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};
