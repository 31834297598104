import React from "react";
import { Modal } from "components/modal/Modal";
import { useTranslation } from "react-i18next";
import { ModalVariant } from "components/modal/Modal.types";
import { FileType } from "reedy-data/models";

import { IconProps } from "components/icon/Icon";
import { OnixGenerationModalStatus } from "pages/ProjectDetails/types/types";
import { GeneratingSkeleton } from "./GeneratingSkeleton";
import { getOnixExportData } from "./utils";
import { useApi } from "../../../../hooks/useApi";

export const OnixGenerationModal = ({
  showOnixExportModal,
  setShowOnixExportModal,
  generatingStatus,
  setGeneratingStatus,
  onixUrl,
}: {
  showOnixExportModal: boolean;
  setShowOnixExportModal: (show: boolean) => void;
  setGeneratingStatus: (status: OnixGenerationModalStatus) => void;
  generatingStatus: OnixGenerationModalStatus;
  onixUrl?: string | null;
}) => {
  const { t } = useTranslation(["projectDetails"]);
  const api = useApi();

  const {
    title,
    description,
    cancelLabel,
    confirmLabel,
    variant,
    confirmIconName,
  } = getOnixExportData(t, generatingStatus);

  const onOpenChange = () => {
    setShowOnixExportModal(false);
    setGeneratingStatus(OnixGenerationModalStatus.IDLE);
  };

  const onConfirm = onixUrl
    ? () => {
        api.files.getFile(onixUrl, FileType.ONIX_EXPORT).then((blob) => {
          if (!blob) {
            return;
          }
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", onixUrl);
          document.body.appendChild(link);
          link.click();
          link.remove();
        });
      }
    : undefined;

  return (
    <Modal
      id="onix-generation"
      variant={variant as ModalVariant}
      title={title}
      description={description}
      cancelLabel={cancelLabel}
      confirmLabel={confirmLabel}
      confirmIconName={confirmIconName as IconProps["name"]}
      cancelIconName="x"
      open={showOnixExportModal}
      onConfirm={onConfirm}
      onOpenChange={onOpenChange}
    >
      {generatingStatus === OnixGenerationModalStatus.REQUESTED ? (
        <GeneratingSkeleton />
      ) : null}
    </Modal>
  );
};
