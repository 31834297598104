import {
  collection,
  CollectionReference,
  QueryDocumentSnapshot,
  Timestamp,
} from "firebase/firestore";

import { db } from "integrations/firebase/firestore";
import { FirestoreOrganisation } from "__generated__/models";

interface FirestoreOrganisationFromFirebase
  extends Omit<FirestoreOrganisation, "deletedAt" | "hardDeletedAt"> {
  deletedAt?: Timestamp;
  hardDeletedAt?: Timestamp;
}

const organisationConverter = {
  toFirestore(organisation: FirestoreOrganisation) {
    return organisation;
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot<FirestoreOrganisationFromFirebase>,
  ): FirestoreOrganisation {
    const organisation = snapshot.data();
    return {
      ...organisation,
      deletedAt: organisation.deletedAt?.toDate(),
      hardDeletedAt: organisation.hardDeletedAt?.toDate(),
    };
  },
};

export const getOrganisationsCollection =
  (): CollectionReference<FirestoreOrganisation> =>
    collection(db, "organisations").withConverter(organisationConverter);
