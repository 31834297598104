import { useCallback, useEffect, useState } from "react";
import { FileType } from "reedy-data/models";
import { useOrganisation } from "contexts/OrganisationProvider";
import { LoaderIcon } from "lucide-react";
import { logError } from "shared/services/ErrorReporting";
import { toast } from "utils/toast";
import { compareDesc } from "date-fns";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { query, where } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { OrganisationOnixUploadFileUpload } from "./components/OrganisationOnixUploadFileUpload";
import { OrganisationOnixUploadFile } from "./components/OrganisationOnixUploadFile";
import { OrganisationOnixUploadFileReportDialog } from "./components/OrganisationOnixUploadFileReportDialog";
import LoadingSpinner from "../../../../../components/loading/LoadingSpinner";
import { getFilesCollection } from "../../../../../integrations/firebase/collections";

export const OrganisationOnixUploadPage = () => {
  const [filename, setFilename] = useState<string | null>(null);
  const [uploading, setUploading] = useState<File | null>(null);
  const { t } = useTranslation(["settings"]);

  const { addFile, id: organisationId } = useOrganisation();

  const [unsortedFiles = [], loading] = useCollectionData(
    query(
      getFilesCollection(),
      where("organisation.id", "==", organisationId),
      where("mode", "==", FileType.MANUAL_ONIX_IMPORT),
      // For unknown reason this doesn't work, so we have to sort the files manually
      // orderBy("createdAt", "desc"),
    ),
  );

  const files = unsortedFiles.toSorted((a, b) =>
    compareDesc(a.createdAt || new Date(), b.createdAt || new Date()),
  );

  const handleFileUpload = useCallback(
    ([file]: File[]) => {
      setUploading(file);
      addFile(file)
        .catch((error) => {
          logError(error.message);
          toast.error(error.message);
        })
        .then((result) =>
          setUploading(
            result
              ? new File([file], result.name.replace(/^manualImport\//, ""), {
                  type: file.type,
                })
              : null,
          ),
        )
        .then(() => {
          toast.success(t("settings:organisation.onix.uploading"), {
            icon: <LoaderIcon className="animate-spin duration-2000 w-5 h-5" />,
          });
        });
    },
    [addFile, t],
  );

  useEffect(() => {
    if (
      uploading &&
      unsortedFiles.some((file) => file.filename === uploading.name)
    ) {
      setUploading(null);
    }
  }, [unsortedFiles, uploading]);

  return (
    <div>
      <OrganisationOnixUploadFileUpload onFileUpload={handleFileUpload} />
      {loading ? (
        <div className="flex items-center justify-center w-full pt-10">
          <LoadingSpinner size="100px" />
        </div>
      ) : null}
      <ul className="mt-8 flex flex-col gap-2">
        {uploading ? (
          <OrganisationOnixUploadFile
            organisationId={organisationId}
            fileName={uploading.name}
            dummy
            fileSizeMB={(uploading.size || 0) / 1024 / 1024}
          />
        ) : null}
        {files.map((file) => (
          <OrganisationOnixUploadFile
            key={String(file.createdAt)}
            organisationId={organisationId}
            fileName={file.filename}
            fileSizeMB={(file.size || 0) / 1024 / 1024}
            createdAt={file.createdAt}
            onActionClick={() => setFilename(file.filename)}
          />
        ))}
      </ul>
      <OrganisationOnixUploadFileReportDialog
        open={!!filename}
        fileName={filename}
        organisationId={organisationId}
        onClose={() => setFilename(null)}
      />
    </div>
  );
};
