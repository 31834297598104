import { useTable } from "hooks/useTable";
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
} from "components/table";
import { useTranslation } from "react-i18next";
import { IUser } from "integrations/firebase/collections";
import { flexRender } from "@tanstack/react-table";
import { PropsWithChildren } from "react";
import { useDialog } from "hooks/useDialog";
import { columns } from "./organisationMembersTableColumns";
import { OrganisationAdminRequiredModal } from "./OrganisationAdminRequiredModal";

interface OrganisationMembersTableProps {
  members: IUser[];
}

export const OrganisationMembersTable = ({
  members,
}: PropsWithChildren<OrganisationMembersTableProps>) => {
  const { t } = useTranslation();

  const adminRequiredModal = useDialog("organisation-admin-required");

  const { table } = useTable({
    columns: columns(t, { showAdminRequiredModal: adminRequiredModal.open }),
    data: members,
    totalRecords: members.length,
    enableRowSelection: false,
  });

  return (
    <div>
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows.map((row: any) => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell: any) => (
                <TableCell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, {
                    ...cell.getContext(),
                    row,
                  })}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <OrganisationAdminRequiredModal control={adminRequiredModal} />
    </div>
  );
};
