import { PropsWithChildren } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PageTitle } from "components/page/PageTitle";
import { PageSubtitle } from "components/page/PageSubtitle";
import {
  OrganisationProvider,
  useOrganisation,
} from "contexts/OrganisationProvider";
import { Tabs, TabsList } from "components/ui/tabs";
import { cn } from "utils";

export const OrganisationPageLayout = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation("settings");
  const { organisation, members } = useOrganisation();

  // Common className function for NavLinks to avoid repetition
  const getNavLinkClassName = ({ isActive }: { isActive: boolean }) =>
    cn(
      "inline-flex items-center justify-center whitespace-nowrap px-3 py-1.5 text-sm font-medium transition-all relative",
      isActive
        ? "text-primary-500 after:absolute after:h-0.5 after:bg-primary-500 after:bottom-0 after:left-0 after:right-0"
        : "text-secondary-400",
    );

  return (
    <div className="py-10 space-y-8">
      <div className="px-28 border-b">
        <div className="space-y-1 mb-4">
          <PageTitle>{organisation?.name ?? `\u00A0`}</PageTitle>
          <PageSubtitle>
            {t("settings:organisation.overview.header.members", {
              count: members.length,
            })}
          </PageSubtitle>
        </div>

        <div className="-mb-1">
          <Tabs>
            <TabsList className="border-b-0">
              <NavLink to="settings" className={getNavLinkClassName}>
                {t("settings:organisation.layout.tabs.settings")}
              </NavLink>

              <NavLink to="members" className={getNavLinkClassName}>
                {t("settings:organisation.layout.tabs.members")}
              </NavLink>

              <NavLink to="onix-upload" className={getNavLinkClassName}>
                {t("settings:organisation.layout.tabs.onix-upload")}
              </NavLink>

              <NavLink to="status" className={getNavLinkClassName}>
                {t("settings:organisation.layout.tabs.status")}
              </NavLink>
            </TabsList>
          </Tabs>
        </div>
      </div>

      <div className="px-28">{children}</div>
    </div>
  );
};

export function OrganisationLayout({ children }: PropsWithChildren) {
  const id = useParams().organisationId!;

  return (
    <OrganisationProvider id={id}>
      <OrganisationPageLayout>{children}</OrganisationPageLayout>
    </OrganisationProvider>
  );
}
