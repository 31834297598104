import { flexRender } from "@tanstack/react-table";
import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableHeader,
} from "components/table";
import { useTrends } from "contexts/TrendsContext";
import { useTable } from "hooks/useTable";
import { IProduct } from "integrations/firebase/collections";
import { useTranslation } from "react-i18next";
import { columns } from "./columns";
import { PreHeader } from "./preHeader";
import { SelectedTableBody } from "./SelectedTableBody";

const emptyArray: IProduct[] = [];

export const SelectedTable = () => {
  const { t } = useTranslation("selectedSearchTerms");
  const { currentTrendWithProducts } = useTrends();

  const { table } = useTable({
    columns: columns(t),
    data: currentTrendWithProducts?.products || emptyArray,
    totalRecords: currentTrendWithProducts?.products?.length || 0,
  });

  return (
    <div className="space-y-4 text-left">
      <PreHeader table={table} />
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          <SelectedTableBody table={table} />
        </TableBody>
      </Table>
    </div>
  );
};
