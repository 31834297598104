import { flexRender, Table } from "@tanstack/react-table";
import { TableCell, TableRow } from "components/ui/table";
import { useTrends } from "contexts/TrendsContext";
import { IProduct } from "integrations/firebase/collections";
import { useTranslation } from "react-i18next";
import { LoadingSpinner } from "components/loading/LoadingSpinner";
import { columns } from "./columns";

export const SelectedTableBody = ({ table }: { table: Table<IProduct> }) => {
  const { t } = useTranslation("selectedSearchTerms");
  const { isLoadingTrends } = useTrends();

  if (isLoadingTrends) {
    return <LoadingSpinner size="40" />;
  }

  if (table.getRowModel().rows?.length) {
    return table.getRowModel().rows.map((row) => (
      <TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
        {row.getVisibleCells().map((cell) => (
          <TableCell key={cell.id}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </TableCell>
        ))}
      </TableRow>
    ));
  }

  return (
    <TableRow>
      <TableCell colSpan={columns.length} className="h-24 text-center">
        {t("selectedSearchTerms:noProjectsFound")}
      </TableCell>
    </TableRow>
  );
};
