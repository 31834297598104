import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { ChevronDown } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from "components/ui/tooltip";
import { IProduct } from "integrations/firebase/collections";
import { saveProductsAsExcel } from "shared/services/ExportProductsService";
import { useAnalytics } from "contexts/AnalyticsContext";
import { useOnixGeneration } from "hooks/useOnixGeneration";
import { cn } from "utils";
import { useFeatureFlags } from "contexts/FeatureFlagContext";
import { toast } from "sonner";
import { Modal } from "components/modal/Modal";
import { useProjectDetails } from "../contexts/ProjectDetailsContext";
import { ExportOption } from "./ExportOption";
import { OnixGenerationModal } from "./OnixGenerationModal";

export const ExportDropdown = ({
  selectedProducts = [],
}: {
  selectedProducts: IProduct[];
}) => {
  const { t } = useTranslation(["projectDetails"]);
  const { features } = useFeatureFlags();
  const { gaEvent } = useAnalytics();
  const { project } = useProjectDetails();
  const [showOnixWarning, setShowOnixWarning] = useState(false);

  const {
    showOnixExportModal,
    setShowOnixExportModal,
    onixUrl,
    handleOnixExportButtonClick,
    generatingStatus,
    setGeneratingStatus,
  } = useOnixGeneration();

  const handleExcelExportButtonClick = useCallback(async () => {
    try {
      gaEvent({
        type: "export_books",
        payload: {
          books_count: selectedProducts.length,
          export_type: "excel",
        },
      });
      await saveProductsAsExcel(selectedProducts, project?.name || "project");
    } catch (error) {
      toast.error(t("projectDetails:buttons.export.error"));
    }
  }, [gaEvent, selectedProducts, project?.name, t]);

  const handleOnixWarningConfirm = useCallback(async () => {
    setShowOnixWarning(false);
    await handleOnixExportButtonClick(selectedProducts);
  }, [handleOnixExportButtonClick, selectedProducts]);

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger
          disabled={selectedProducts.length === 0}
          role="button"
          aria-label={t("projectDetails:buttons.export.title")}
          className={cn(
            "disabled:text-secondary-400 text-secondary-700 group rounded-lg",
            selectedProducts.length === 0 ? "bg-primary-200" : "bg-primary-500",
          )}
        >
          <Tooltip>
            <TooltipTrigger asChild>
              <div
                aria-label="export-tooltip-trigger"
                className="rounded-lg border flex-1 flex group-aria-expanded:border-secondary-600 cursor-pointer transition-colors"
              >
                <div className="text-white whitespace-nowrap font-normal flex items-center px-5 text-md">
                  {generatingStatus === "REQUESTED"
                    ? t("projectDetails:buttons.export.exporting")
                    : t("projectDetails:buttons.export.title")}
                </div>

                <div className="transition-colors w-11 h-full border-l aspect-square flex items-center justify-center group-aria-expanded:border-l-secondary-600">
                  <ChevronDown
                    color="white"
                    className="w-5 h-5 transition-transform group-aria-expanded:rotate-180"
                  />
                </div>
              </div>
            </TooltipTrigger>

            {selectedProducts.length === 0 ? (
              <TooltipContent
                data-testid="generate-all-tooltip"
                role="tooltip"
                aria-label="buttons.export.disabledNoBooks"
                side="bottom"
                className="font-light text-center shadow-tooltip text-neutral-600 whitespace-pre px-5 z-10 border-none leading-relaxed"
                sideOffset={6}
              >
                <TooltipArrow
                  className="fill-white drop-shadow-sm"
                  width={12}
                  height={6}
                />
                {t("projectDetails:buttons.export.disabledNoBooks")}
              </TooltipContent>
            ) : null}
          </Tooltip>
        </DropdownMenuTrigger>

        <DropdownMenuContent
          side="bottom"
          align="center"
          className="popover-content-width-full"
        >
          <ExportOption
            onClick={handleExcelExportButtonClick}
            label={t("projectDetails:buttons.export.items.excel")}
          />
          {features.onixExport && (
            <ExportOption
              disabled={generatingStatus === "REQUESTED"}
              onClick={() => setShowOnixWarning(true)}
              label={t("projectDetails:buttons.export.items.onix")}
            />
          )}
        </DropdownMenuContent>
      </DropdownMenu>

      <Modal
        id="onix-warning"
        open={showOnixWarning}
        onOpenChange={setShowOnixWarning}
        title={t("projectDetails:modals.exportOnix.warning.title")}
        description={t("projectDetails:modals.exportOnix.warning.description")}
        confirmLabel={t("projectDetails:modals.exportOnix.warning.confirm")}
        cancelLabel={t("projectDetails:modals.exportOnix.warning.cancel")}
        variant="warning"
        onConfirm={handleOnixWarningConfirm}
      />

      <OnixGenerationModal
        onixUrl={onixUrl}
        showOnixExportModal={showOnixExportModal}
        setShowOnixExportModal={setShowOnixExportModal}
        generatingStatus={generatingStatus}
        setGeneratingStatus={setGeneratingStatus}
      />
    </>
  );
};
