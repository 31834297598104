import { Client } from "@hey-api/client-fetch";
import { OrganisationsApi } from "./organisations";
import { BaseApi } from "./base";

export class AccountApi extends BaseApi {
  organisations: OrganisationsApi;

  constructor(client: Client) {
    super(client);

    this.organisations = new OrganisationsApi(client);
  }
}
