import { memo } from "react";
import { getRandomNumber } from "utils/helpers";
import { Column } from "@tanstack/react-table";
import { Skeleton } from "../ui/skeleton";
import { TableRow } from "./table-row";
import { TableCell } from "./table-cell";

export const SkeletonRows = memo(
  ({ columns }: { columns: Column<any, unknown>[] }) => {
    return (
      <>
        {Array.from({ length: 10 }).map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <TableRow key={i}>
            {columns.map((column, index) => (
              <TableCell
                key={column.id}
                style={{ width: column.getSize() || undefined }}
              >
                <Skeleton
                  className="h-[22px]"
                  style={{
                    width: `${index === 0 ? 0 : getRandomNumber(5, 10)}rem`,
                  }}
                />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </>
    );
  },
);

SkeletonRows.displayName = "SkeletonRows";
