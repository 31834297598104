import {
  addDoc,
  and,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  or,
  orderBy,
  query,
  QueryDocumentSnapshot,
  QueryFilterConstraint,
  QueryNonFilterConstraint,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "integrations/firebase/firestore";
import isNumber from "lodash/isNumber";
import { FirestoreProject } from "reedy-data/models";

export type { FirestoreProject };

export interface IProject extends Omit<FirestoreProject, "isbns"> {
  id: string;
  productIds: string[];
}

const ProjectConverter = {
  toFirestore(project: Partial<IProject>) {
    const { createdAt, productIds, ...rest } = project;
    const newProject = {
      ...rest,
      ...(createdAt ? { createdAt: Timestamp.fromDate(createdAt) } : {}),
      ...(productIds ? { isbns: productIds } : {}),
    };

    return newProject;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): IProject {
    const data = snapshot.data();

    return {
      ...data,
      id: snapshot.id,
      createdAt: data.createdAt.toDate(),
      productIds: data.isbns,
      userId: data.userId ?? data.user?.uid,
    } as IProject;
  },
};

export const getProjectsCollection = () =>
  collection(db, "projects").withConverter(ProjectConverter);

type PaginationOptions = {
  pageSize: number;
  currentPage: number;
};

export const getProjectsQuery = (
  organisationId: string,
  userId?: string | null,
  pageSize?: number,
) => {
  const filters: QueryFilterConstraint[] = [
    where("organisation.id", "==", organisationId),
  ];

  if (userId) {
    filters.push(
      or(where("userId", "==", userId), where("user.uid", "==", userId)),
    );
  }

  const constraints: QueryNonFilterConstraint[] = [
    orderBy("createdAt", "desc"),
  ];

  if (isNumber(pageSize)) {
    constraints.push(limit(pageSize));
  }

  return query(getProjectsCollection(), and(...filters), ...constraints);
};

export const getPaginatedProjects = async (
  organisationId: string,
  userId: string | null,
  { pageSize, currentPage }: PaginationOptions,
) => {
  const projectsQuery = getProjectsQuery(
    organisationId,
    userId,
    pageSize * (currentPage + 3),
  );

  const snapshot = await getDocs(projectsQuery);
  const currentPageProjects = snapshot.docs.map((document) => ({
    ...document.data(),
    id: document.id,
  })) as IProject[];
  return currentPageProjects;
};

export const getProject = async (projectId: string) => {
  const projectDoc = await getDoc(doc(getProjectsCollection(), projectId));
  if (!projectDoc.exists()) {
    throw new Error("Project not found");
  }
  return {
    ...projectDoc.data(),
    id: projectDoc.id,
  } as IProject;
};

export type UpdateProjectFields = Partial<
  Pick<IProject, "productIds" | "name">
>;

export type CreateProjectFields = Omit<IProject, "id">;

export const addProject = async (project: CreateProjectFields) =>
  addDoc(getProjectsCollection(), project);

export const updateProject = async (
  projectId: string,
  projectFields: UpdateProjectFields,
) => {
  const projectRef = doc(getProjectsCollection(), projectId);
  await updateDoc(projectRef, ProjectConverter.toFirestore(projectFields));
};
