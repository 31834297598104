import { SearchQueryRequest } from "__generated__/api";
import entries from "lodash/entries";

import { parse } from "date-fns";
import {
  CheckboxFilterSchema,
  DateFilterSchema,
  FilterItem,
  FilterTabs,
  FilterType,
  NumericFilterSchema,
  SearchFilterComparisonOperator,
  SearchQuery,
  SearchQueryFilters,
  SearchQuerySchema,
  SearchType,
} from "./types";
import { DATE_FORMAT } from "./constants";

/*  --Functions to parse url params to form filters?--  */
const parseNumericFilter = (filter: string): SearchQueryFilters | undefined => {
  const [query, value] = filter.split(":");
  const [variable, operator] = query.split(".");

  if (!variable || !operator || Number.isNaN(Number(value))) {
    return;
  }

  return {
    [variable as keyof SearchQueryFilters]: NumericFilterSchema.parse({
      value: Number(value),
      comparisonOperator: operator as SearchFilterComparisonOperator,
      type: FilterType.NUMERIC,
    }),
  };
};

const parseDateRangeFilter = (
  filter: string,
): SearchQueryFilters | undefined => {
  const [variable, range] = filter.split(":");
  const [start, end] = range.slice(1, -1).split("..");
  if (!variable || !start || !end) {
    return;
  }
  let tab = FilterTabs.RANGE;
  if (start === end) {
    tab = FilterTabs.SPECIFIC;
  }
  return {
    [variable as keyof SearchQueryFilters]: DateFilterSchema.parse({
      tab,
      range: {
        from: start,
        to: end,
      },
      type: FilterType.DATE_RANGE,
    }),
  };
};

const parseCheckboxFilter = (
  filter: string,
): SearchQueryFilters | undefined => {
  const [variable, values] = filter.split(":");
  const valuesArray = values.slice(1, -1).split(",");

  if (!variable || !valuesArray) {
    return;
  }

  return {
    [variable as keyof SearchQueryFilters]: CheckboxFilterSchema.parse({
      values: valuesArray,
      type: FilterType.CHECKBOX,
    }),
  };
};

export function parseParamFilters(
  filters: string[],
  defaultFilters: FilterItem[],
): SearchQuery["filters"] {
  if (!filters.length) {
    return {};
  }

  const parsedFilters: SearchQuery["filters"] = {};
  filters.forEach((filter) => {
    const [variable] = filter.split(/[^a-zA-Z0-9]+/);
    const filterType = defaultFilters.find((f) => f.name === variable)?.type;
    if (!filterType) {
      return;
    }

    let parsed: SearchQueryFilters | undefined;
    switch (filterType) {
      case FilterType.NUMERIC:
        parsed = parseNumericFilter(filter);
        if (parsed) {
          Object.assign(parsedFilters, parsed);
        }
        break;
      case FilterType.DATE_RANGE:
        parsed = parseDateRangeFilter(filter);

        if (parsed) {
          Object.assign(parsedFilters, parsed);
        }
        break;
      case FilterType.CHECKBOX:
        parsed = parseCheckboxFilter(filter);
        if (parsed) {
          Object.assign(parsedFilters, parsed);
        }
        break;
      default:
        break;
    }
  });
  return parsedFilters;
}

export function paramsToSearchQuery(
  params: URLSearchParams,
  defaultFilters: FilterItem[],
): SearchQuery {
  const filters = parseParamFilters(params.getAll("filter"), defaultFilters);
  const query = params.get("q");
  const type = params.get("type");
  const page = params.get("page");
  const pageSize = params.get("pageSize");

  return SearchQuerySchema.parse({
    type: type ?? SearchType.QUICK,
    query: query ?? "",
    filters,
    page,
    pageSize,
  });
}

export const DEFAULT_PARAMS: SearchQueryRequest = {
  model: "products",
  q: "",
  query_by: "title,author,identifier",
  sort_by: "publishedAt:desc",
  filter_by: "",
  page: 1,
  per_page: 10,
  prefix: "false",
} as const;

export function parseComparisonOperator(
  comparisonOperator: SearchFilterComparisonOperator,
) {
  switch (comparisonOperator) {
    case SearchFilterComparisonOperator.EQUALS:
      return "=";
    case SearchFilterComparisonOperator.LESS:
      return "<";
    case SearchFilterComparisonOperator.GREATER:
      return ">";
    default:
      throw new Error("Invalid comparison operator");
  }
}

/*  --Function to parse url filters to typesense filter--  */

const SECONDS_IN_MS = 1000;

export function datesToISOString(start: string, end: string) {
  const parseToUTC = (dateStr: string) => {
    const parsedDate = parse(dateStr, DATE_FORMAT, new Date());

    return new Date(
      Date.UTC(
        parsedDate.getFullYear(),
        parsedDate.getMonth(),
        parsedDate.getDate(),
      ),
    );
  };

  const startDate = parseToUTC(start);
  const endDate = parseToUTC(end);

  endDate.setUTCHours(23, 59, 59, 999);

  return [
    Math.floor(startDate.getTime() / SECONDS_IN_MS),
    Math.floor(endDate.getTime() / SECONDS_IN_MS),
  ];
}

export function searchQueryFiltersToFilter(filters?: SearchQueryFilters) {
  const conditions: string[] = [];

  if (!filters) {
    return "";
  }

  entries(filters).forEach(([variable, filter]) => {
    if (!filter) {
      return;
    }

    if (filter.type === FilterType.NUMERIC) {
      const operator = filter.comparisonOperator
        ? parseComparisonOperator(filter.comparisonOperator)
        : undefined;
      conditions.push(`${variable}:${operator}${filter.value}`);
    } else if (filter.type === FilterType.DATE_RANGE && filter.range) {
      const { from, to } = filter.range;
      const [start, end] = datesToISOString(from, to);

      conditions.push(`${variable}:[${start}..${end}]`);
    } else if (filter.type === FilterType.CHECKBOX) {
      conditions.push(`${variable}:[${filter.values.join(",")}]`);
    }
  });

  return conditions.join(" && ");
}

export function searchQueryToSearchRequest(
  query: Partial<SearchQuery>,
): SearchQueryRequest {
  const baseRequest: SearchQueryRequest = {
    ...DEFAULT_PARAMS,
    q: query.query || "",
    page: query.page ?? 1,
    per_page: query.pageSize ?? 10,
    filter_by: searchQueryFiltersToFilter(query.filters),
    query_by: query.query_by || DEFAULT_PARAMS.query_by,
    facet_by: query.facet_by || DEFAULT_PARAMS.facet_by,
    facet_query: query.facet_query || undefined,
  };

  switch (query.type) {
    case SearchType.SEMANTIC: {
      return {
        ...baseRequest,
        q: query.query || "*",
        page: 0,
        query_by: "summaryEmbedding",
        sort_by: "_vector_distance:asc",
      };
    }
    case SearchType.ISBN: {
      return {
        ...baseRequest,
        prefix: undefined,
        q: "",
        filter_by: query.query ? `identifier: [${query.query}]` : "",
      };
    }
    default: {
      return baseRequest;
    }
  }
}

export const vectorDistanceToPercentage = (distance: number) => {
  // typesense returns a number between 0 and 2, we convert it to a percentage
  const percentage = 100 - distance * 50;

  // First clamp the input to 0-100
  const clampedPercentage = Math.min(100, Math.max(0, percentage));

  // Scale the percentage so that 60% = 0% and 80% = 100%
  const scaledPercentage = Math.round(((clampedPercentage - 60) / 20) * 100);

  // Clamp the final result to 0-100 range
  return Math.min(100, Math.max(0, scaledPercentage));
};
