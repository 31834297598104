import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { isProduction, meticulousDisabled } from "shared/constants/Environment";
import { tryLoadAndStartRecorder } from "@alwaysmeticulous/recorder-loader";
import App from "./App";
import "./shared/i18n";
import { initialiseLaunchDarkly } from "./integrations/launch-darkly";
import { TooltipProvider } from "./components/ui/tooltip";

const root = ReactDOM.createRoot(document.getElementById("root")!);

const load = async () => {
  const LaunchDarklyProvider = await initialiseLaunchDarkly();

  if (!isProduction() && !meticulousDisabled) {
    console.info("Starting Meticulous recorder");
    // Start the Meticulous recorder before you initialise your app.
    // Note: all errors are caught and logged, so no need to surround with try/catch
    await tryLoadAndStartRecorder({
      recordingToken: import.meta.env.VITE_METICULOUS_RECORDING_TOKEN,
      isProduction: false,
    });
  }

  root.render(
    <React.StrictMode>
      <LaunchDarklyProvider>
        <TooltipProvider delayDuration={100}>
          <App />
        </TooltipProvider>
      </LaunchDarklyProvider>
    </React.StrictMode>,
  );
};

load().then();
