import { IconName } from "components/icon/Icon";
import { isValid, parse } from "date-fns";
import { KebabCase } from "utils/types";
import { z } from "zod";
import { DATE_FORMAT } from "./constants";

export enum SearchFilterComparisonOperator {
  EQUALS = "eq",
  LESS = "lt",
  GREATER = "gt",
}

export enum SearchType {
  QUICK = "quick",
  ISBN = "isbn",
  SEMANTIC = "semantic",
}

export enum FilterTabs {
  SPECIFIC = "specific",
  RANGE = "range",
}

export enum FilterType {
  CHECKBOX = "checkbox",
  NUMERIC = "numeric",
  DATE_RANGE = "date_range",
}

export type FilterItem = {
  icon: KebabCase<IconName>;
  label: string;
  type: FilterType;
  name: string;
  show: boolean;
  placeholder?: string;
  noResultLabel?: string;
  badgeLabel?: string;
};

export const NumericFilterSchema = z.object({
  value: z.number().optional(),
  comparisonOperator: z.nativeEnum(SearchFilterComparisonOperator).optional(),
  type: z.literal(FilterType.NUMERIC),
});

export const ValidNumericFilterSchema = NumericFilterSchema.refine(
  (data) => data.value !== null && data.value !== undefined,
);

export const DateFilterSchema = z.object({
  tab: z.nativeEnum(FilterTabs).optional(),
  range: z
    .object({
      from: z.string().refine((date) => {
        const parsedDate = parse(date, DATE_FORMAT, new Date());
        return isValid(parsedDate);
      }),
      to: z.string().refine((date) => {
        const parsedDate = parse(date, DATE_FORMAT, new Date());
        return isValid(parsedDate);
      }),
    })
    .optional(),
  type: z.literal(FilterType.DATE_RANGE).optional(),
});

export const CheckboxFilterSchema = z.object({
  values: z.array(z.string()),
  type: z.literal(FilterType.CHECKBOX),
});

export const ValidCheckboxFilterSchema = CheckboxFilterSchema.refine(
  (data) => data.values.length > 0,
);

export const SearchQueryFiltersSchema = z.object({
  keywordCount: NumericFilterSchema.nullish(),
  publishedAt: DateFilterSchema.nullish(),
  productGroupDescription: CheckboxFilterSchema.nullish(),
  publisher: CheckboxFilterSchema.nullish(),
});

export type SearchQueryFilters = z.infer<typeof SearchQueryFiltersSchema>;

export const SearchQuerySchema = z.object({
  query: z.string().default(""),
  query_by: z.string().nullish(),
  facet_by: z.string().nullish(),
  facet_query: z.string().nullish(),
  max_facet_values: z.number().nullish(),
  type: z.nativeEnum(SearchType).optional().default(SearchType.QUICK),
  filters: SearchQueryFiltersSchema,
  page: z.coerce.number().nullish(),
  pageSize: z.coerce.number().nullish(),
});

export type SearchQuery = z.infer<typeof SearchQuerySchema>;

export type NumericFilter = z.infer<typeof NumericFilterSchema>;
export type DateFilter = z.infer<typeof DateFilterSchema>;
export type CheckboxFilter = z.infer<typeof CheckboxFilterSchema>;

export type Filter = NumericFilter | DateFilter | CheckboxFilter;
