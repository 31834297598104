import { useProduct } from "contexts/ProductContext";
import { GridLoading } from "components/ui/grid";
import { ListLoading } from "components/skeletons/ListLoading";
import { useTranslation } from "react-i18next";
import { useProductActions } from "hooks/useProductActions";
import { actionInProgress } from "utils/status";
import { EmptyList } from "components/empty/EmptyList";
import { Button } from "components/button";
import { QualifiersList } from "./QualifiersList";
import { SubjectsList } from "./SubjectsList/SubjectsList";
import { useSubjectsData } from "./SubjectsList/useSubjectsData";

export const GeneratedCodes = () => {
  const { product } = useProduct();
  const { t } = useTranslation(["productDetails"]);
  const { generateProductSubjects } = useProductActions();
  const { isEmptyList: isEmptySubjectsList } = useSubjectsData(
    "generated",
    product,
  );

  const { isEmptyList: isEmptyQualifiersList } = useSubjectsData(
    "generated",
    product,
  );

  const isGeneratingProduct = actionInProgress(
    product?.actions?.thema?.actions?.generate,
  );

  if (isGeneratingProduct) {
    return (
      <GridLoading className="w-full h-full" loading>
        <div className="relative w-80 ml-20 align-middle">
          <div className="absolute -left-64">
            <ListLoading />
          </div>
          <p className="text-center text-sm font-medium">
            {t("thema.loading")}
          </p>
        </div>
      </GridLoading>
    );
  }

  if (isEmptySubjectsList && isEmptyQualifiersList) {
    return (
      <EmptyList
        title={t("thema.sections.noSubjects.generated.title")}
        text={t("thema.sections.noSubjects.generated.description")}
        orientation="horizontal"
        buttons={[
          <Button
            key="button_generate_keywords"
            label={t("general:buttons.generate")}
            icon="loader"
            onClick={() => generateProductSubjects(product?.id || "")}
            size="small"
            analyticsId="button_generate_keywords"
          />,
        ]}
      />
    );
  }

  return (
    <div
      className="flex flex-row gap-4 flex-1 h-full"
      data-testid="generated-codes"
    >
      <SubjectsList />
      <QualifiersList />
    </div>
  );
};
