import { useTranslation } from "react-i18next";

import { useFeatureFlags } from "contexts/FeatureFlagContext";
import { FilterItem, FilterType } from "./types";

export const useFilters = () => {
  const { t } = useTranslation(["books", "general"]);
  const { features } = useFeatureFlags();

  const filters: FilterItem[] = [
    {
      icon: "square-stack",
      label: t("books:filters.keywordCount"),
      type: FilterType.NUMERIC,
      name: "keywordCount",
      show: true,
    },
    {
      icon: "calendar-days",
      label: t("books:filters.publishingDate"),
      type: FilterType.DATE_RANGE,
      name: "publishedAt",
      show: features.publishedDateFilter,
    },
    {
      icon: "book",
      label: t("books:filters.bookFormat.label"),
      placeholder: t("books:filters.bookFormat.placeholder"),
      type: FilterType.CHECKBOX,
      name: "productGroupDescription",
      show: features.bookFormatFilter,
      noResultLabel: t("books:filters.bookFormat.noResultLabel"),
      badgeLabel: t("books:filters.bookFormat.badgeLabel"),
    },
    {
      icon: "school",
      label: t("books:filters.publisher.label"),
      placeholder: t("books:filters.publisher.placeholder"),
      type: FilterType.CHECKBOX,
      name: "publisher",
      show: features.publisherFilter,
      noResultLabel: t("books:filters.publisher.noResultLabel"),
      badgeLabel: t("books:filters.publisher.badgeLabel"),
    },
  ];

  return { filters };
};
