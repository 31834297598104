import { type ColumnDef } from "components/table";
import { IProject } from "integrations/firebase/collections";
import { DataTableColumnHeader } from "components/ui/data-table-column-header";
import { UserProfile } from "components/user/UserProfile";

import { TFunction } from "i18next";

export const columns: (t: TFunction) => ColumnDef<IProject>[] = (
  t: TFunction,
) => [
  {
    accessorKey: "name",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        className="pl-2"
        title={t("components:projectsTable.columns.name")}
      />
    ),
    cell: ({ row }) => (
      <div className="w-[40%] pl-2">{row.getValue("name")}</div>
    ),
  },
  {
    accessorKey: "books",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("components:projectsTable.columns.books")}
      />
    ),
    cell: ({ row }) => {
      const books = (row.original.productIds as string[]) || [];
      return (
        <div className="flex items-center">
          <span>
            {`${books.length} ${books.length === 1 ? "book" : "books"}`}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: "createdAt",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("components:projectsTable.columns.date")}
      />
    ),
    cell: ({ row }) => (
      <div className="flex space-x-2">
        {row.original?.createdAt?.toDateString() ?? "-"}
      </div>
    ),
  },
  {
    accessorKey: "userId",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("components:projectsTable.columns.creator")}
      />
    ),
    cell: ({ row }) => {
      return <UserProfile userId={row.getValue("userId")} />;
    },

    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
];
