import { useTranslation } from "react-i18next";
import { useProductActions } from "hooks/useProductActions";
import { useErrorToast } from "hooks/useErrorToast";
import { useProduct } from "contexts/ProductContext";
import { logError } from "shared/services/ErrorReporting";
import { useMemo } from "react";
import { BasePanel } from "components/BasePanel";
import { Button } from "components/button";
import { EmptyList } from "components/empty/EmptyList";
import { useSubjectsData } from "./useSubjectsData";
import { SubjectItem } from "../SubjectItem";

export interface SubjectsListProps {
  variant?: "database" | "generated";
}

export const SubjectsList = ({ variant = "generated" }: SubjectsListProps) => {
  const { t } = useTranslation(["productDetails"]);
  const { product } = useProduct();
  const { generateProductSubjects, errorGenerate } = useProductActions();

  const { selectedSubjects, isEmptyList, finalSubjects } = useSubjectsData(
    variant,
    product,
  );

  const finalSubjectIds = useMemo(
    () => finalSubjects.map((subject) => subject.id),
    [finalSubjects],
  );

  useErrorToast(errorGenerate ? t("thema.error.generate") : undefined);

  const handleGenerate = () => {
    if (!product) {
      logError("Product not found while generating thema");
      return;
    }
    generateProductSubjects(product.id);
  };

  return (
    <BasePanel
      title={t("thema.headers.subjects")}
      isEmpty={isEmptyList}
      emptyComponent={
        <EmptyList
          title={
            variant === "database"
              ? t("thema.sections.noSubjects.database.title")
              : t("thema.sections.noSubjects.generated.title")
          }
          text={
            variant === "database"
              ? t("thema.sections.noSubjects.database.description")
              : t("thema.sections.noSubjects.generated.description")
          }
          buttons={
            variant === "generated"
              ? [
                  <Button
                    key="generate"
                    label={t("thema.sections.noSubjects.generated.generate")}
                    onClick={handleGenerate}
                    icon="loader"
                    size="small"
                    analyticsId="button_generate_subjects"
                  />,
                ]
              : undefined
          }
        />
      }
    >
      {selectedSubjects.map((item) => (
        <SubjectItem
          key={item.id}
          testId={`add-subject-${item.subjectCode}-button`}
          subject={item}
          isSelected={finalSubjectIds.includes(item.id)}
        />
      ))}
    </BasePanel>
  );
};
