import { collection, doc, QueryDocumentSnapshot } from "firebase/firestore";

import { db } from "integrations/firebase/firestore";
import { FirestoreOnixExport } from "reedy-data/models";

interface FirestoreOnixExportFromFirebase extends FirestoreOnixExport {}

const onixExportConverter = {
  toFirestore: (onixExport: FirestoreOnixExport) => onixExport,
  fromFirestore(
    snapshot: QueryDocumentSnapshot<FirestoreOnixExportFromFirebase>,
  ): FirestoreOnixExport & { id: string } {
    const data = snapshot.data();

    return {
      ...data,
      id: snapshot.id,
    };
  },
};

export const getOnixExportsCollection = () =>
  collection(db, "onixExports").withConverter(onixExportConverter);

export const getOnixExportDocument = (id: string) =>
  doc(db, "onixExports", id).withConverter(onixExportConverter);
