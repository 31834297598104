import { SkeletonText, SkeletonTextVariant } from "./SkeletonText";

export interface SkeletonTextsProps {
  variant?: SkeletonTextVariant;
}

export const SkeletonTexts = ({ variant }: SkeletonTextsProps) => {
  return (
    <div className="relative mt-4">
      <SkeletonText
        animated={false}
        className="scale-90 -top-6 absolute -z-10"
      />
      <SkeletonText
        animated={false}
        className="scale-95 -top-3 absolute -z-10"
      />
      <SkeletonText animated={false} variant={variant} />
    </div>
  );
};
