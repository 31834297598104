import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "components/button";
import { useTrends } from "contexts/TrendsContext";
import { TabHeader } from "components/TabHeader";

export const Header = () => {
  const { t } = useTranslation(["searchTerms"]);
  const { canGoBack, canGoNext, goBack, goNext, currentTrendWithProducts } =
    useTrends();
  const navigate = useNavigate();

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <Button
        variant="ghost"
        icon="arrow-left"
        className="-ml-4"
        label={t("searchTerms:header.title")}
        onClick={navigateBack}
      />
      <div className="flex items-center justify-between">
        <TabHeader
          title={currentTrendWithProducts?.term ?? ""}
          subtitle={`${currentTrendWithProducts?.productsCount} books`}
        />

        <div className="flex flex-row gap-2">
          <Button
            disabled={!canGoBack}
            onClick={goBack}
            variant="tertiary"
            icon="arrow-left"
            label={t("searchTerms:searchTermsList.selectedBooks.previousTerm")}
            analyticsId="button_prior_term"
          />
          <Button
            disabled={!canGoNext}
            onClick={goNext}
            variant="tertiary"
            iconPosition="right"
            icon="arrow-right"
            label={t("searchTerms:searchTermsList.selectedBooks.nextTerm")}
            analyticsId="button_next_term"
          />
        </div>
      </div>
    </div>
  );
};
