import { useTranslation } from "react-i18next";
import { useProductActions } from "hooks/useProductActions";
import { useErrorToast } from "hooks/useErrorToast";
import { useProduct } from "contexts/ProductContext";
import { Modal } from "components/modal/Modal";
import { useMemo } from "react";

export const RegenerateKeywordsDialog = ({
  onOpenChange,
  isOpen,
}: {
  onOpenChange: (open: boolean) => void;
  isOpen: boolean;
}) => {
  const { product } = useProduct();
  const { t } = useTranslation(["general", "productDetails"]);
  const { generateProductKeywords, errorGenerate, errorUpdate } =
    useProductActions();

  const errorMessage = useMemo(() => {
    if (errorGenerate) {
      return t("productDetails:generatedKeywords.errorToast.errorGenerate");
    }
    if (errorUpdate) {
      return t("productDetails:generatedKeywords.errorToast.errorUpdate");
    }
    return null;
  }, [errorGenerate, errorUpdate, t]);

  useErrorToast(errorMessage);
  const handleRegenerateAllKeywordsModal = () => {
    if (product) {
      generateProductKeywords(product, { regenerate: true });
    }
  };

  return (
    <Modal
      id="regenerate-keywords"
      open={isOpen}
      variant="warning"
      title={t(
        "productDetails:generatedKeywords.regenerateKeywordsModal.header",
      )}
      description={t(
        "productDetails:generatedKeywords.regenerateKeywordsModal.message",
      )}
      confirmLabel={t("general:buttons.regenerate")}
      confirmIconName="loader"
      cancelLabel={t("general:buttons.cancel")}
      onOpenChange={onOpenChange}
      onConfirm={handleRegenerateAllKeywordsModal}
      aria-describedby={t(
        "productDetails:generatedKeywords.regenerateKeywordsModal.header",
      )}
    />
  );
};
