export interface LaunchDarklyFeatureFlags {
  themaCodes: boolean;
  operationalThemaVersion: string;
  flagsAuthLoaded: boolean;
  rankKeywords: boolean;
  onixExport: boolean;
  evaluation: boolean;
  cancelEvaluation: boolean;
  operationalMaintenanceMode: boolean;
  publishedDateFilter: boolean;
  bookFormatFilter: boolean;
  publisherFilter: boolean;
  uploadManuscript: boolean;
}

export function parseFeatureFlags(flags: Partial<LaunchDarklyFeatureFlags>) {
  return {
    themaCodes: flags.themaCodes ?? false,
    operationalThemaVersion: flags.operationalThemaVersion ?? "-",
    flagsAuthLoaded: flags.flagsAuthLoaded ?? false,
    rankKeywords: flags.rankKeywords ?? true,
    onixExport: flags.onixExport ?? false,
    evaluation: flags.evaluation ?? false,
    cancelEvaluation: flags.cancelEvaluation ?? false,
    maintenanceMode: flags.operationalMaintenanceMode ?? false,
    publishedDateFilter: flags.publishedDateFilter ?? true,
    bookFormatFilter: flags.bookFormatFilter ?? true,
    publisherFilter: flags.publisherFilter ?? true,
    uploadManuscript: flags.uploadManuscript ?? false,
  };
}

export type FeatureFlags = ReturnType<typeof parseFeatureFlags>;
