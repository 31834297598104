import { Skeleton } from "components/ui/skeleton";
import { cn } from "utils";
import { cva } from "class-variance-authority";

export type SkeletonTextVariant = "secondary" | "primary" | "dark" | "error";

export interface SkeletonTextProps {
  variant?: SkeletonTextVariant;
  className?: string;
  animated?: boolean;
}

const skeletonTextStyles = cva(
  "border-[1.15px] rounded-lg p-4 bg-white flex flex-col items-center gap-1",
  {
    variants: {
      variant: {
        secondary: "border-secondary-200",
        primary: "border-primary-300 shadow-[0px_6px_12px_0px_#528BFF14]",
        dark: "border-secondary-400",
        error: "border-system-error shadow-[0px_6px_12px_0px_#DC262614]",
      },
    },
    defaultVariants: {
      variant: "secondary",
    },
  },
);

export const SkeletonText = ({
  variant = "secondary",
  className,
  animated = true,
}: SkeletonTextProps) => {
  return (
    <div className={cn(skeletonTextStyles({ variant }), className)}>
      <Skeleton animated={animated} className="h-4 w-60 bg-secondary-100" />
      <Skeleton animated={false} className="h-2 w-60 bg-transparent" />

      <Skeleton animated={animated} className="h-1 w-60 bg-secondary-100" />
      <Skeleton animated={animated} className="h-1 w-60 bg-secondary-100" />
      <Skeleton animated={animated} className="h-1 w-60 bg-secondary-100" />
      <Skeleton animated={false} className="h-2 w-60 bg-transparent" />

      <Skeleton animated={animated} className="h-1 w-60 bg-secondary-100" />
      <Skeleton animated={animated} className="h-1 w-60 bg-secondary-100" />
      <Skeleton animated={animated} className="h-1 w-60 bg-secondary-100" />
      <Skeleton animated={false} className="h-2 w-60 bg-transparent" />

      <div className="flex flex-row justify-end w-full">
        <Skeleton animated={animated} className="h-3 w-10 bg-secondary-100" />
      </div>
    </div>
  );
};
