import { useCallback, useMemo, useState } from "react";
import { useAnalytics } from "contexts/AnalyticsContext";
import { Button } from "components/button";
import { useTranslation } from "react-i18next";
import { copyToClipboard } from "utils/helpers";
import { toast } from "utils/toast";
import { useProduct } from "contexts/ProductContext";
import { BasePanel } from "components/BasePanel";
import { useFeatureFlags } from "contexts/FeatureFlagContext";
import { useProductActions } from "hooks/useProductActions";
import { actionInProgress } from "utils/status";
import {
  BaseItem,
  SortableList,
} from "pages/ProductDetails/components/SortableList";
import {
  createListItems,
  createManualSubject,
} from "pages/ProductDetails/tabs/ThemaTab/utils";
import { EmptyList } from "components/empty/EmptyList";
import { useSubjects } from "../../../hooks/useSubjects";
import InputMode from "./InputMode";
import { CircleColorWarningCounterKeywordsCharactersCount } from "./CircleColorWarningCounterKeywordsCharactersCount";
import { googleAnalyticsKeywordSelectionReport } from "../../../../../../shared/services/KeywordService";
import { getKeywordsByType } from "../../../../../../integrations/firebase/collections";

export const FinalKeywords = () => {
  const { t } = useTranslation(["productDetails"]);
  const { product } = useProduct();
  const { final: finalKeywords, generated: generatedKeywords } =
    getKeywordsByType(product);
  const [inputMode, setInputMode] = useState(false);
  const { features } = useFeatureFlags();
  const { autofillProductKeywords } = useProductActions();

  const { gaEvent } = useAnalytics();
  const { handleChangeOrder, handleAddSubject, handleChangeManualSubject } =
    useSubjects();

  const finalKeywordsListItems = useMemo(
    () => createListItems(finalKeywords),
    [finalKeywords],
  );

  const hasKeywords = finalKeywords.length > 0;
  const finalKeywordsCharacters = finalKeywords.join("").trim().length;

  const finalKeywordsMap = useMemo(
    () => new Map(finalKeywords.map((subject) => [subject.id, subject])),
    [finalKeywords],
  );

  const onCopy = useCallback(async () => {
    gaEvent({
      type: "copy_keywords_from_final_selection",
      payload: googleAnalyticsKeywordSelectionReport(product),
    });

    await copyToClipboard({
      arrayToCopy: finalKeywords.map(
        (keyword) => keyword.subjectHeadingText ?? "",
      ),
    });

    toast.success(t("finalKeywords.toasts.copy.header"));
  }, [finalKeywords, t, gaEvent, product]);

  const handleChangeOrderWrapper = (items: BaseItem[]) => {
    if (product) {
      handleChangeOrder(items, product.id, "keyword");
    }
  };

  const onSaveKeywords = useCallback(
    async (keyword: string) => {
      try {
        const manualSubject = createManualSubject(keyword);
        await handleAddSubject(manualSubject);
      } catch (error) {
        toast.error(t("errorToast.errorUpdate"));
      }
    },
    [handleAddSubject, t],
  );

  const handleRemoveFinalSubjectsListItem = useCallback(
    (item: BaseItem | undefined) => {
      if (!item) return;

      const subjectItem = finalKeywordsMap.get(item.id);
      if (subjectItem) {
        handleAddSubject(subjectItem);
        gaEvent({
          type: "remove_keyword_from_final_selection",
          payload: {
            book: product?.id ?? "",
            keyword: subjectItem.subjectHeadingText ?? "",
          },
        });
      }
    },
    [finalKeywordsMap, handleAddSubject, gaEvent, product],
  );

  const hasGeneratedKeywords = generatedKeywords.length > 0;
  const isAutoFillDisabled =
    !hasGeneratedKeywords || product?.actions?.keywords?.active;
  const isAutoFilling = actionInProgress(
    product?.actions?.keywords?.actions?.autoFill,
  );

  const onAutofill = () => {
    if (!product) return;
    autofillProductKeywords(product);
  };

  return (
    <BasePanel
      title={t("finalKeywords.header")}
      isEmpty={!hasKeywords && !inputMode}
      headerClassName="bg-primary-50"
      className="max-w-80"
      emptyComponent={
        <EmptyList
          title={t("productDetails:finalKeywords.empty.header")}
          text={t("productDetails:finalKeywords.empty.description")}
          buttons={
            features.rankKeywords
              ? [
                  <Button
                    key="autofill"
                    label={t("productDetails:finalKeywords.empty.autofill")}
                    onClick={onAutofill}
                    disabled={isAutoFillDisabled || !hasGeneratedKeywords}
                    icon={isAutoFilling ? "loader" : "sparkles"}
                    size="small"
                    analyticsId="button_autofill_keywords"
                  />,
                ]
              : undefined
          }
        />
      }
      headerAction={
        <Button
          variant="link"
          icon="plus"
          onClick={() => setInputMode(true)}
          disabled={inputMode}
          testId="add-keyword-button"
          analyticsId="button_add_final_keyword"
        />
      }
      footer={
        <div className="flex justify-between border-t border-secondary-200 bg-background p-4">
          <Button
            size="small"
            variant="tertiary"
            icon="copy"
            label={t("finalKeywords.actions.copy")}
            disabled={!hasKeywords}
            onClick={onCopy}
          />
          <CircleColorWarningCounterKeywordsCharactersCount
            count={finalKeywordsCharacters || 0}
          />
        </div>
      }
    >
      <div className="flex-1 flex flex-col min-h-0">
        <div className="flex-1 overflow-y-auto">
          <SortableList
            items={finalKeywordsListItems}
            onChangeOrder={handleChangeOrderWrapper}
            onRemove={handleRemoveFinalSubjectsListItem}
            onEdit={handleChangeManualSubject}
          />

          {inputMode && (
            <InputMode
              product={product}
              saveFinalKeywords={onSaveKeywords}
              setInputMode={setInputMode}
            />
          )}
        </div>
      </div>
    </BasePanel>
  );
};
