import {
  addOrganisationUsers,
  deleteOrganisation,
  undeleteOrganisation,
  deleteOrganisationUser,
  OrganisationUsersCreateRequest,
  patchUser,
  postOrganisationFileRoute,
  UserRole,
  postOrganisation,
  transferUserOrganisation,
  updateOrganisationSettings,
} from "__generated__/api";
import { BaseApi } from "./base";

export class OrganisationsApi extends BaseApi {
  async createOrganisation(organisation: { name: string }) {
    const response = await this.request(postOrganisation, {
      body: organisation,
    });

    return response?.data;
  }

  async addFile(organisationId: string, file: File) {
    const response = await this.request(postOrganisationFileRoute, {
      path: { identifier: organisationId, file_name: file.name },
      query: { no_sign: true },
      body: { upload: file },
    });

    return response?.data;
  }

  async addUsers(organisationId: string, body: OrganisationUsersCreateRequest) {
    const response = await this.request(addOrganisationUsers, {
      path: { identifier: organisationId },
      body,
    });

    return response?.data;
  }

  async deleteUser(organisationId: string, userId: string) {
    const response = await this.request(deleteOrganisationUser, {
      path: {
        identifier: organisationId,
        useridentifier: userId,
      },
    });

    return response?.data;
  }

  async updateUserRole(organisationId: string, userId: string, role: UserRole) {
    const response = await this.request(patchUser, {
      path: {
        identifier: organisationId,
        useridentifier: userId,
      },
      body: {
        roles: [role],
      },
    });

    return response?.data;
  }

  async deleteOrganisation(organisationId: string) {
    const response = await this.request(deleteOrganisation, {
      path: { identifier: organisationId },
    });

    return response?.data;
  }

  async transferUser(sourceId: string, destinationId: string, userId: string) {
    const response = await this.request(transferUserOrganisation, {
      path: {
        source_id: sourceId,
        destination_id: destinationId,
        useridentifier: userId,
      },
    });
    return response?.data;
  }

  async undeleteOrganisation(organisationId: string) {
    const response = await this.request(undeleteOrganisation, {
      path: { identifier: organisationId },
    });

    return response?.data;
  }

  async updateOrganisationSettings(
    organisationId: string,
    inactiveProductStatus: string[],
  ): Promise<any> {
    const response = await this.request(updateOrganisationSettings, {
      path: { identifier: organisationId },
      body: {
        inactiveProductStatus,
      },
    });

    return response?.data || null;
  }
}
