import { useLayoutEffect, useRef, useState } from "react";
import { Badge } from "components/ui/badge";
import { useFormContext } from "react-hook-form";
import { FilterType, SearchQueryFilters } from "hooks/search/types";
import { parseComparisonOperator } from "hooks/search/utils";
import { useTranslation } from "react-i18next";
import { useSearchQuery } from "hooks/search/useSearchQuery";
import { Icon } from "components/icon/Icon";
import { useFilters } from "hooks/search/useFilters";
import { isValidFilter } from "./utils";
import { defaultFilterValues } from "./constants";

const MAX_WIDTH = 150;

export const ActiveFilterBadge = ({ filterName }: { filterName: string }) => {
  const { watch, setValue } = useFormContext();
  const { removeQueryParameter } = useSearchQuery();
  const { t } = useTranslation(["general"]);
  const { filters } = useFilters();
  const filter = watch(`filters.${filterName}`);
  const isFilterActive = isValidFilter(filter);

  const badgeRef = useRef<HTMLDivElement>(null);
  const clearButtonRef = useRef<HTMLDivElement>(null);
  const [maxWidth, setMaxWidth] = useState<number | undefined>(undefined);

  useLayoutEffect(() => {
    if (badgeRef.current && clearButtonRef.current) {
      const badgeWidth = badgeRef.current.offsetWidth;
      const clearButtonWidth = Math.max(75, badgeWidth);
      setMaxWidth(clearButtonWidth < MAX_WIDTH ? clearButtonWidth : MAX_WIDTH);
    }
  }, [filter]);

  if (!isFilterActive) {
    return (
      <div
        className="px-2 ml-2 flex justify-end"
        style={{ width: maxWidth, minWidth: 75 }}
      >
        <Icon name="chevron-right" />
      </div>
    );
  }

  const filterLabel = (() => {
    switch (filter.type) {
      case FilterType.NUMERIC:
        return `${parseComparisonOperator(filter.comparisonOperator)} ${filter.value}`;
      case FilterType.DATE_RANGE: {
        if (filter.range.from !== filter.range.to) {
          const fromYear = filter.range.from.split("-")[0];
          const toYear = filter.range.to.split("-")[0];
          if (fromYear === toYear) {
            return `${filter.range.from.replace(/-/g, ".")} - ${filter.range.to.slice(5, filter.range.to.length).replace(/-/g, ".")}`;
          }
          return `${filter.range.from.replace(/-/g, ".")} - ${filter.range.to.replace(/-/g, ".")}`;
        }
        return filter.range.from.replace(/-/g, ".");
      }
      case FilterType.CHECKBOX: {
        return filter.values.length > 1
          ? `${filter.values.length} ${filters.find((f) => f.name === filterName)?.badgeLabel}`
          : filter.values[0];
      }
      default:
        break;
    }

    return filter.value;
  })();

  const resetFilter = () => {
    if (filterName in defaultFilterValues) {
      setValue(
        `filters.${filterName}`,
        defaultFilterValues[filterName as keyof typeof defaultFilterValues],
      );
    }
    removeQueryParameter(filterName as keyof SearchQueryFilters);
  };

  return (
    <div
      className="p-2 ml-2 flex justify-end flex-1"
      style={{ width: maxWidth, minWidth: 75 }}
    >
      <div ref={badgeRef} className="group-hover:hidden block overflow-hidden">
        <Badge
          variant="secondary"
          className={`min-w-2 px-2 max-w-[${MAX_WIDTH}px] py-1 block whitespace-nowrap text-secondary-600 text-2xs overflow-hidden text-ellipsis`}
          aria-label={filterLabel}
        >
          {filterLabel}
        </Badge>
      </div>
      <div ref={clearButtonRef} className="group-hover:block hidden">
        <Badge
          variant="secondary"
          onClick={resetFilter}
          role="button"
          className="ml-2 min-w-4 cursor-pointer whitespace-nowrap text-2xs py-1"
          aria-label={t("general:buttons.clear", {
            filter: filterLabel,
          })}
        >
          {t("general:buttons.clear")}
        </Badge>
      </div>
    </div>
  );
};
