import {
  useCallback,
  useMemo,
  useState,
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
} from "react";
import {
  getProductsByIds,
  getProductsByTrendForCountry,
  IProduct,
  IProductsByTrend,
} from "integrations/firebase/collections";
import { useAuth } from "contexts/AuthContext";
import { logError } from "shared/services/ErrorReporting";
import { TrendsType } from "reedy-data/models";

export interface ITrendWithProducts {
  id?: string;
  term: string;
  productsIds: string[];
  productsCount: number;
  products: IProduct[] | null;
  projectId?: string | null;
  rank?: number;
}

const trendTypes = [TrendsType.GOOGLE, TrendsType.AMAZON];
const top3CountryCodes = ["DE", "GB", "US"];

interface ITrendsContext {
  top3CountryCodes: string[];
  selectedCountryCode: string;
  setSelectedCountryCode: (value: string) => void;
  trendTypes: TrendsType[];
  selectedTrendsType: TrendsType;
  setSelectedTrendsType: (type: TrendsType) => void;
  indexCurrentTrendWithProducts: number;
  setIndexCurrentTrendWithProducts: (value: number) => void;
  currentTrendWithProducts: ITrendWithProducts | undefined;
  setCurrentTrendWithProducts: (value: ITrendWithProducts | undefined) => void;
  isLoadingProductsForCurrentTrendWithProducts: boolean;
  canGoNext: boolean;
  goNext: () => void;
  canGoBack: boolean;
  goBack: () => void;
  selectedTrendsWithProducts: ITrendWithProducts[];
  setSelectedTrendsWithProducts: (
    trendsWithProducts: ITrendWithProducts[],
  ) => void;
  trendsWithProducts: ITrendWithProducts[];
  errorTrends: Error | undefined;
  trendsThatHaveProducts: ITrendWithProducts[];
  trends: IProductsByTrend[];
  isLoadingTrends: boolean;
  getTrendsOfCountry: (countryCode: string) => void;
}

export const TrendsContext = createContext<ITrendsContext>(undefined as never);

export const TrendsContextProvider = ({ children }: PropsWithChildren) => {
  const { userData } = useAuth();
  const organisation = useMemo(
    () => userData?.organisation ?? null,
    [userData],
  );

  const [selectedCountryCode, setSelectedCountryCode] = useState(
    top3CountryCodes[0],
  );
  const [selectedTrendsType, setSelectedTrendsType] = useState(
    TrendsType.GOOGLE,
  );
  const [indexCurrentTrendWithProducts, setIndexCurrentTrendWithProducts] =
    useState(0);
  const [currentTrendWithProducts, setCurrentTrendWithProducts] =
    useState<ITrendWithProducts>();
  const [
    isLoadingProductsForCurrentTrendWithProducts,
    setIsLoadingProductsForCurrentTrendWithProducts,
  ] = useState(false);
  const [selectedTrendsWithProducts, setSelectedTrendsWithProducts] = useState<
    ITrendWithProducts[]
  >([]);
  const [trends, setTrends] = useState<IProductsByTrend[]>([]);
  const [isLoadingTrends, setIsLoadingTrends] = useState(false);
  const [errorTrends, setErrorTrends] = useState<Error>();

  const getTrendsOfCountry = useCallback(
    async (countryCode: string) => {
      if (
        !organisation ||
        (selectedTrendsType === TrendsType.GOOGLE && !countryCode)
      )
        return;

      setIsLoadingTrends(true);
      setErrorTrends(undefined);

      try {
        const loadedTrends = await getProductsByTrendForCountry(
          organisation.id,
          selectedTrendsType,
          countryCode,
        );
        setTrends(loadedTrends);
      } catch (err) {
        logError(err);
        setErrorTrends(err as Error);
      } finally {
        setIsLoadingTrends(false);
      }
    },
    [selectedTrendsType, organisation],
  );

  const trendsWithProducts = useMemo(
    () =>
      trends.map(({ trend, products }) => ({
        ...trend,
        id: trend.uuid,
        productsIds: products,
        productsCount: products.length,
        products: null,
      })),
    [trends],
  );
  const trendsThatHaveProducts = useMemo(
    () => trendsWithProducts.filter((x) => x.productsCount > 0),
    [trendsWithProducts],
  );

  const canGoBack = useMemo(
    () =>
      !isLoadingProductsForCurrentTrendWithProducts &&
      indexCurrentTrendWithProducts > 0,
    [
      isLoadingProductsForCurrentTrendWithProducts,
      indexCurrentTrendWithProducts,
    ],
  );

  const canGoNext = useMemo(
    () =>
      !isLoadingProductsForCurrentTrendWithProducts &&
      indexCurrentTrendWithProducts < selectedTrendsWithProducts.length - 1,
    [
      isLoadingProductsForCurrentTrendWithProducts,
      indexCurrentTrendWithProducts,
      selectedTrendsWithProducts,
    ],
  );

  const fetchProductsForCurrentTrend = useCallback(async () => {
    if (
      !organisation ||
      !currentTrendWithProducts ||
      currentTrendWithProducts.products !== null
    )
      return;

    setIsLoadingProductsForCurrentTrendWithProducts(true);

    try {
      const ids = currentTrendWithProducts.productsIds;
      const idsChunks = Array.from(
        { length: Math.ceil(ids.length / 10) },
        (_, i) => ids.slice(i * 10, (i + 1) * 10),
      );

      const productsChunks = await Promise.all(
        idsChunks.map((idChunk) => getProductsByIds(organisation.id, idChunk)),
      );

      const products = productsChunks.flat();

      setCurrentTrendWithProducts((prev) => prev && { ...prev, products });
    } finally {
      setIsLoadingProductsForCurrentTrendWithProducts(false);
    }
  }, [organisation, currentTrendWithProducts]);

  const goNext = useCallback(
    () => canGoNext && setIndexCurrentTrendWithProducts((c) => c + 1),
    [canGoNext],
  );
  const goBack = useCallback(
    () => canGoBack && setIndexCurrentTrendWithProducts((c) => c - 1),
    [canGoBack],
  );

  useEffect(() => {
    setCurrentTrendWithProducts(
      selectedTrendsWithProducts[indexCurrentTrendWithProducts],
    );
  }, [indexCurrentTrendWithProducts, selectedTrendsWithProducts]);

  useEffect(() => {
    fetchProductsForCurrentTrend();
  }, [fetchProductsForCurrentTrend]);

  useEffect(() => {
    getTrendsOfCountry(selectedCountryCode);
  }, [getTrendsOfCountry, selectedCountryCode]);

  const trendsContextProviderValue = useMemo(
    () => ({
      top3CountryCodes,
      selectedCountryCode,
      setSelectedCountryCode,
      trendTypes,
      selectedTrendsType,
      setSelectedTrendsType,
      indexCurrentTrendWithProducts,
      setIndexCurrentTrendWithProducts,
      currentTrendWithProducts,
      setCurrentTrendWithProducts,
      isLoadingProductsForCurrentTrendWithProducts,
      canGoNext,
      goNext,
      canGoBack,
      goBack,
      selectedTrendsWithProducts,
      setSelectedTrendsWithProducts,
      trendsWithProducts,
      errorTrends,
      trendsThatHaveProducts,
      trends,
      isLoadingTrends,
      getTrendsOfCountry,
    }),
    [
      selectedCountryCode,
      selectedTrendsType,
      indexCurrentTrendWithProducts,
      currentTrendWithProducts,
      isLoadingProductsForCurrentTrendWithProducts,
      canGoNext,
      goNext,
      canGoBack,
      goBack,
      selectedTrendsWithProducts,
      trendsWithProducts,
      errorTrends,
      trendsThatHaveProducts,
      trends,
      isLoadingTrends,
      getTrendsOfCountry,
    ],
  );

  return (
    <TrendsContext value={trendsContextProviderValue}>{children}</TrendsContext>
  );
};

export const useTrends = () => {
  const ctxt = useContext(TrendsContext);
  if (!ctxt) {
    throw new Error("useTrends must be used within a TrendsContextProvider");
  }
  return ctxt;
};
