import { collection, QueryDocumentSnapshot } from "firebase/firestore";

import { db } from "integrations/firebase/firestore";
import { PipelineImportReport } from "reedy-data/models";

interface PipelineImportReportFromFirebase extends PipelineImportReport {}

const pipelineReportConverter = {
  toFirestore: (file: PipelineImportReport) => file,
  fromFirestore(
    snapshot: QueryDocumentSnapshot<PipelineImportReportFromFirebase>,
  ): PipelineImportReport & { id: string } {
    const data = snapshot.data();

    return {
      ...data,
      id: snapshot.id,
    };
  },
};

export const getPipelineReportCollection = () =>
  collection(db, "pipelineReporting").withConverter(pipelineReportConverter);
