import { createClient } from "@hey-api/client-fetch";
import { search, SearchQueryRequest } from "__generated__/api";
import { AccountApi } from "./account";
import { ProductsApi } from "./products";
import { UsersApi } from "./users";
import { EvaluationApi } from "./evaluation";
import { BaseApi } from "./base";
import { FilesApi } from "./files";

export const baseUrl = import.meta.env.VITE_API_URL;

export class Api extends BaseApi {
  account: AccountApi;

  products: ProductsApi;

  evaluation: EvaluationApi;

  users: UsersApi;

  files: FilesApi;

  constructor() {
    const client = createClient({ baseUrl });

    super(client);

    this.account = new AccountApi(client);
    this.products = new ProductsApi(client);
    this.evaluation = new EvaluationApi(client);
    this.users = new UsersApi(client);
    this.files = new FilesApi(client);
  }

  async search(query: SearchQueryRequest) {
    const response = await this.request(search, {
      body: query,
    });

    return response?.data;
  }

  setAuthToken(authToken?: string) {
    this.client.setConfig({
      auth: authToken,
    });
  }
}

export const api = new Api();
