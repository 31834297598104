import {
  exportProducts,
  generateProductDescription,
  generateProductDescriptionBulk,
  generateProductKeywords,
  generateProductKeywordsBulk,
  generateProductThema,
} from "__generated__/api";
import { BaseApi } from "./base";

export interface DescriptionType {
  textType: string;
  audience: string;
}

export class ProductsApi extends BaseApi {
  async exportProducts(ids: string[]) {
    if (ids.length === 0) {
      throw new Error("Product IDs array cannot be empty");
    }

    const response = await this.request(exportProducts, {
      body: { productIds: ids },
    });

    return response?.data;
  }

  async generateDescription(
    productId: string,
    options: {
      hash: string;
      targetGroup: string | null;
      specialRequest: string | null;
    },
  ) {
    const response = await this.request(generateProductDescription, {
      path: { identifier: productId },
      body: {
        hash: options.hash,
        targetGroup: options.targetGroup,
        specialRequest: options.specialRequest,
      },
    });

    return response?.data;
  }

  async generateDescriptionsBulk(
    productIds: string[],
    descriptionTypes: DescriptionType[],
  ) {
    const response = await this.request(generateProductDescriptionBulk, {
      body: { identifiers: productIds, filters: descriptionTypes },
    });

    return response?.data;
  }

  async generateKeywords(productId: string) {
    const response = await this.request(generateProductKeywords, {
      path: { identifier: productId },
      body: { generate: true },
    });

    return response?.data;
  }

  async generateKeywordsBulk(productIds: string[], autoFill?: boolean) {
    const response = await this.request(generateProductKeywordsBulk, {
      body: {
        generate: true,
        identifiers: productIds,
        autoFill,
      },
    });

    return response?.data;
  }

  async generateThema(productId: string) {
    const response = await this.request(generateProductThema, {
      path: { identifier: productId },
    });

    return response?.data;
  }

  async autofillKeywords(productId: string) {
    const response = await this.request(generateProductKeywords, {
      path: { identifier: productId },
      body: { autoFill: true },
    });

    return response?.data;
  }
}
