import { useFormContext } from "react-hook-form";
import { parse } from "date-fns";

import { IconInput } from "components/input/IconInput";
import { Calendar } from "components/ui/calendar";
import { ChangeEvent, useState, useEffect } from "react";
import { DATE_FORMAT } from "hooks/search/constants";
import { useTranslation } from "react-i18next";
import {
  getErrorMessage,
  isValidFilterDate,
  transformToInputDate,
} from "../utils";

export const SpecificDateFilter = ({ name }: { name: string }) => {
  const { setValue, watch } = useFormContext();
  const { t } = useTranslation(["books"]);
  const formDate = watch(`filters.${name}.range`);
  const [error, setError] = useState("");

  useEffect(() => {
    setError(getErrorMessage(formDate, t));
  }, [formDate, t]);

  const getParsedDate = (
    date: { from?: string; to?: string } | undefined,
  ): Date | undefined => {
    return date?.from ? parse(date.from, DATE_FORMAT, new Date()) : undefined;
  };

  const handleCalendarChange = (date: Date | undefined) => {
    if (!date) return;
    const updatedRange = {
      from: transformToInputDate(date),
      to: transformToInputDate(date),
    };
    setValue(`filters.${name}.range`, updatedRange);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const inputValue = e.target.value;
    if (isValidFilterDate(inputValue)) {
      handleCalendarChange(parse(inputValue, DATE_FORMAT, new Date()));
    } else {
      setValue(`filters.${name}.range`, { from: inputValue, to: inputValue });
    }
  };

  return (
    <div className="p-2">
      <IconInput
        iconName="calendar-days"
        aria-label="specific date input"
        value={formDate?.from || ""}
        placeholder="DD.MM.YYYY"
        onChange={handleInputChange}
      />
      {error && <p className="text-red-500 text-xs self-start">{error}</p>}
      <Calendar
        onSelect={handleCalendarChange}
        selected={getParsedDate(formDate)}
        mode="single"
      />
    </div>
  );
};
