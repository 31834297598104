import { useCallback, useMemo } from "react";
import { useProduct } from "contexts/ProductContext";
import { useTranslation } from "react-i18next";
import {
  BaseItem,
  SortableList,
} from "pages/ProductDetails/components/SortableList";
import { BasePanel } from "components/BasePanel";
import { EmptyList } from "components/empty/EmptyList";
import { useSubjects } from "../../../hooks/useSubjects";
import { Section } from "./Section";

import { createListItems, filterQualifiers, filterSubjects } from "../../utils";

export const FinalThemaList = () => {
  const { t } = useTranslation(["productDetails"]);
  const { product } = useProduct();
  const { handleChangeOrder, handleAddSubject } = useSubjects();

  const finalSubjects = useMemo(
    () => filterSubjects(product?.subjects?.final || []),
    [product],
  );
  const finalQualifiers = useMemo(
    () => filterQualifiers(product?.subjects?.final || []),
    [product],
  );

  const finalSubjectsListItems = useMemo(
    () => createListItems(finalSubjects),
    [finalSubjects],
  );
  const finalQualifiersListItems = useMemo(
    () => createListItems(finalQualifiers),
    [finalQualifiers],
  );

  const handleChangeOrderWrapper =
    (isSubjectList: boolean) => (items: BaseItem[]) => {
      if (product) {
        handleChangeOrder(
          items,
          product.id,
          isSubjectList ? "subject" : "qualifier",
        );
      }
    };

  const handleRemoveFinalSubjectsListItem = useCallback(
    (item: BaseItem | undefined) => {
      if (!item) return;
      const subjectItem = product?.subjects?.final?.find(
        (subject) => subject.id === item.id,
      );
      if (subjectItem) handleAddSubject(subjectItem);
    },
    [handleAddSubject, product?.subjects?.final],
  );

  if (!product) return null;

  return (
    <BasePanel
      title={t("thema.headers.final")}
      className="w-80 h-full"
      headerClassName="bg-primary-50"
    >
      <div className="flex flex-col h-full overflow-y-auto">
        <Section
          title={t("thema.sections.finalSubjects.section.subjects")}
          contentLength={finalSubjectsListItems.length}
        >
          {!finalSubjects.length && (
            <EmptyList
              title={t("thema.sections.finalSubjects.noSubjects.header")}
              text={t("thema.sections.finalSubjects.noSubjects.description")}
              hideOnShortScreen
            />
          )}
          <SortableList
            items={finalSubjectsListItems}
            onChangeOrder={handleChangeOrderWrapper(true)}
            onRemove={handleRemoveFinalSubjectsListItem}
          />
        </Section>
        <Section
          title={t("thema.sections.finalSubjects.section.qualifiers")}
          contentLength={finalQualifiersListItems.length}
        >
          {!finalQualifiers.length && (
            <EmptyList
              title={t("thema.sections.finalSubjects.noQualifiers.header")}
              text={t("thema.sections.finalSubjects.noQualifiers.description")}
              hideOnShortScreen
            />
          )}
          <SortableList
            items={finalQualifiersListItems as BaseItem[]}
            onChangeOrder={handleChangeOrderWrapper(false)}
            onRemove={handleRemoveFinalSubjectsListItem}
          />
        </Section>
      </div>
    </BasePanel>
  );
};
