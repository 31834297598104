import { Icon } from "components/icon/Icon";
import { Input } from "components/ui/input";
import { Ref } from "react";
import { cn } from "utils";

export type SearchInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  iconClassName?: string;
  ref?: Ref<HTMLInputElement>;
};

const SearchInput = ({
  className,
  iconClassName,
  ref,
  ...props
}: SearchInputProps) => {
  return (
    <div
      className={cn("flex relative items-center  bg-white text-sm", className)}
    >
      <Icon
        name="search"
        iconClassName={cn(
          "absolute left-3 h-4 w-4 text-secondary-400",
          iconClassName,
        )}
      />

      <Input
        {...props}
        className="pl-8 placeholder:text-secondary-400 focus:shadow-outline-primary focus:ring-offset-primary-500 focus:border-primary-500 rounded-lg"
        type="search"
        ref={ref}
      />
    </div>
  );
};

export { SearchInput };
