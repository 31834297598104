/* eslint-disable no-underscore-dangle */
import { Button } from "components/button";
import { useTranslation } from "react-i18next";
import { useProduct } from "contexts/ProductContext";
import { TicketCategory } from "integrations/zendesk";
import { RoutePath } from "shared/constants";
import { FirestoreGenerationStatusCode } from "__generated__/models";
import { EmptyText } from "components/empty/EmptyText";
import { generationErrorMessage } from "../../../../utils";

interface GenerateBookDescriptionErrorProps {
  onClick(): void;

  statusCode?: FirestoreGenerationStatusCode | null;
}

export const GenerateBookDescriptionError = ({
  onClick,
  statusCode,
}: GenerateBookDescriptionErrorProps) => {
  const { t } = useTranslation(["productDetails", "general"]);
  const { product } = useProduct();

  const handleContactSupport = () => {
    const url = new URL(RoutePath.ContactUs, window.location.origin);
    url.searchParams.set("isbn", product?.isbn || "");
    url.searchParams.set(
      "ticketCategory",
      statusCode === FirestoreGenerationStatusCode._402 ||
        statusCode === FirestoreGenerationStatusCode._451
        ? TicketCategory.other
        : TicketCategory.bug,
    );
    window.open(url.toString(), "_blank");
  };

  return (
    <EmptyText
      title={t("productDetails:bookDescription.tabGenerate.error.header")}
      text={
        generationErrorMessage(t, statusCode) ||
        t("productDetails:bookDescription.tabGenerate.error.message", {
          code: statusCode,
        })
      }
      variant="error"
      buttons={[
        <Button
          key="button_contact_support"
          variant="tertiary"
          label={t("general:buttons.contactSupport")}
          disabled={!product}
          onClick={handleContactSupport}
          size="small"
          analyticsId="button_contact_support_on_description_generation_failure"
        />,
        <Button
          key="button_generate_book_description"
          variant="primary"
          label={t("general:buttons.retry")}
          onClick={onClick}
          size="small"
          analyticsId="button_generate_book_description"
        />,
      ]}
    />
  );
};
