import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDialog } from "hooks/useDialog";
import { toast } from "utils/toast";
import { Modal } from "components/modal/Modal";
import { api } from "api/client";
import { Icon } from "components/icon/Icon";

interface DeleteOrganisationModalProps {
  organisationId: string;
  organisationName: string;
}

export const DeleteOrganisationModal = React.forwardRef<
  HTMLButtonElement,
  DeleteOrganisationModalProps
>(({ organisationId, organisationName }, ref) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const { t } = useTranslation(["settings", "general"]);
  const dialog = useDialog("delete-organisation");

  async function onConfirm() {
    if (isDeleting || !organisationId) {
      return;
    }
    setIsDeleting(true);
    try {
      await api.account.organisations.deleteOrganisation(organisationId);

      toast.success(
        t("settings:organisations.overview.delete.success", {
          organisationName,
        }),
      );

      dialog.close();
    } catch (e) {
      toast.error(t("settings:organisations.overview.delete.error"));
    } finally {
      setIsDeleting(false);
    }
  }

  return (
    <>
      <button
        ref={ref}
        className="relative cursor-default select-none rounded-sm px-2 py-1.5 text-sm outline-none text-secondary-900 transition-colors focus:bg-secondary-50 data-[disabled]:pointer-events-none data-[disabled]:opacity-50 flex items-center gap-2 w-full justify-start hover:bg-secondary-50"
        type="button"
        onClick={() => dialog.open()}
        aria-label="Delete Organisation"
        disabled={isDeleting}
      >
        <Icon name="trash2" iconClassName="text-red-500" />
        <span className="text-sm text-secondary-500">
          {t("general:buttons.delete")}
        </span>
      </button>
      <Modal
        id={dialog.id!}
        open={dialog.isOpen}
        mainIconName="octagon-alert"
        variant="danger"
        title={t("settings:organisations.overview.delete.title")}
        description={t("settings:organisations.overview.delete.description", {
          organisationName,
        })}
        confirmLabel={t("general:buttons.delete")}
        confirmIconName="trash2"
        cancelLabel={t("general:buttons.cancel")}
        onOpenChange={dialog.onOpenChange}
        loading={isDeleting}
        onConfirm={() => onConfirm()}
      />
    </>
  );
});

DeleteOrganisationModal.displayName = "DeleteOrganisationModal";
