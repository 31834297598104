import { flexRender } from "@tanstack/react-table";

import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableHeader,
} from "components/table";
import { useMemo } from "react";
import { useTrends } from "contexts/TrendsContext";
import { useTable } from "hooks/useTable";
import { useTranslation } from "react-i18next";
import { getColumns } from "./columns";
import { PreHeader } from "./preHeader";
import { SearchTermsTableBody } from "./SearchTermsTableBody";

export const SearchTermsTable = () => {
  const { t } = useTranslation(["searchTerms"]);
  const { trendsWithProducts = [], isLoadingTrends } = useTrends();

  const columns = useMemo(() => getColumns(t), [t]);

  const { table } = useTable({
    columns,
    data: trendsWithProducts,
    enableRowSelection: true,
    totalRecords: trendsWithProducts?.length || 0,
  });

  return (
    <div className="space-y-4 text-left">
      <PreHeader table={table} />
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{ width: header.getSize() || undefined }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>

        <TableBody>
          <SearchTermsTableBody table={table} isLoading={isLoadingTrends} />
        </TableBody>
      </Table>
    </div>
  );
};
