import { SkeletonTexts } from "components/skeletons/SkeletonTexts";
import { SkeletonTextVariant } from "components/skeletons/SkeletonText";
import { EmptyPanel } from "./EmptyPanel";

export interface EmptyTextProps {
  title: string;
  text: string;
  variant?: SkeletonTextVariant;
  buttons?: React.ReactNode[];
}

export const EmptyText = ({
  title,
  text,
  variant,
  buttons,
}: EmptyTextProps) => {
  return (
    <EmptyPanel
      image={<SkeletonTexts variant={variant} />}
      title={title}
      text={text}
      buttons={buttons}
    />
  );
};
