import { logError } from "shared/services/ErrorReporting";
import { getErrorMessage } from "utils/errorParseHelper";

export type Result<D> = (
  | {
      data: D;
      error: undefined;
    }
  | {
      data: undefined;
      error: unknown;
    }
) & {
  request: Request;
  response: Response;
};

export { getErrorMessage };

export function parseResult<D = any>(
  result: Result<D>,
  request?: any,
  throwOnError: boolean | ((response: Response) => boolean) = true,
) {
  const logWithContext = (e: any) =>
    logError(e, {
      data: {
        ...result,
        requestBody: request,
      },
    });

  const shouldThrow =
    typeof throwOnError === "function"
      ? throwOnError(result.response)
      : throwOnError;

  if (result.error && shouldThrow) {
    const requestErrorMessage = getErrorMessage(result.error);
    logWithContext(requestErrorMessage);
    throw new Error(requestErrorMessage);
  }

  return result.data;
}
