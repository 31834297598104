import { Icon, IconName } from "components/icon/Icon";
import { Button, ButtonVariants } from "components/button";
import { KebabCase } from "utils/types";
import { ReactNode } from "react";
import { cn } from "utils/cn";

interface IContentCard {
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  iconName: KebabCase<IconName>;
  actionIconName: KebabCase<IconName>;
  actionIconClassName?: string;
  actionVariant?: ButtonVariants;
  onClick?: () => void;
  actionTestId?: string;
  button?: boolean;
}

export const ContentCard = ({
  title,
  subtitle,
  iconName,
  actionIconName,
  actionIconClassName,
  actionVariant = "link",
  onClick,
  actionTestId,
  button,
}: IContentCard) => {
  const card = (
    <div className="shadow-sm border bg-white border-secondary-50 rounded-xl flex items-center justify-between px-6 py-3 space-x-8 h-[90px]">
      <div className="flex items-center space-x-4">
        <div className="bg-secondary-25 rounded-full h-12 w-12 shrink-0 flex items-center justify-center">
          <Icon iconClassName="!w-5 !h-5" name={iconName} />
        </div>

        <div className="space-y-0.5">
          <div className="text-secondary-900">{title}</div>

          {subtitle && (
            <div className="text-sm text-secondary-400 space-x-2">
              {subtitle}
            </div>
          )}
        </div>
      </div>
      {onClick && button && (
        <Icon
          name={actionIconName}
          size="medium"
          iconClassName={actionIconClassName}
        />
      )}
      {onClick && !button && (
        <Button
          variant={actionVariant}
          icon={actionIconName}
          onClick={onClick}
          className={cn(
            "flex items-center justify-center h-10 w-10 p-0",
            actionIconClassName,
          )}
          testId={actionTestId ?? "content-card-action"}
        />
      )}
    </div>
  );

  if (button) {
    return (
      <button
        type="button"
        className="w-full text-left cursor-pointer"
        onClick={onClick}
        data-testid={actionTestId ?? "content-card-action"}
      >
        {card}
      </button>
    );
  }

  return card;
};
