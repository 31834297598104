import { useTranslation } from "react-i18next";
import { BasePanel } from "components/BasePanel";
import { useMemo } from "react";
import { EmptyList } from "components/empty/EmptyList";
import { useQualifiersData } from "./useQualifiersData";
import { QualifiersListVariant } from "./types";
import { SubjectItem } from "../SubjectItem";

interface QualifiersListProps {
  variant?: QualifiersListVariant;
}

export const QualifiersList = ({
  variant = "generated",
}: QualifiersListProps) => {
  const { t } = useTranslation(["productDetails"]);
  const { qualifiers, finalQualifiers, isEmptyList } =
    useQualifiersData(variant);

  const finalQualifiersIds = useMemo(
    () => finalQualifiers.map((qualifier) => qualifier.id),
    [finalQualifiers],
  );

  return (
    <BasePanel
      title={t("thema.headers.qualifiers")}
      isEmpty={isEmptyList}
      emptyComponent={
        <EmptyList
          title={
            variant === "generated"
              ? t("thema.sections.noQualifiers.generated.title")
              : t("thema.sections.noQualifiers.database.title")
          }
          text={
            variant === "generated"
              ? t("thema.sections.noQualifiers.generated.description")
              : t("thema.sections.noQualifiers.database.description")
          }
        />
      }
    >
      {qualifiers.map((item) => (
        <SubjectItem
          key={item.id}
          testId={`add-qualifier-${item.subjectCode}-button`}
          subject={item}
          isSelected={finalQualifiersIds.includes(item.id)}
        />
      ))}
    </BasePanel>
  );
};
