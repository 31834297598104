import {
  type ComponentProps,
  forwardRef,
  type MouseEvent,
  useCallback,
} from "react";
import { TableCell as BaseTableCell } from "components/ui/table";
import { ColumnDef } from "./column";
import { cn } from "../../utils";

export interface TableCellProps extends ComponentProps<typeof BaseTableCell> {
  columnDef?: ColumnDef<any>;
}

export const TableCell = forwardRef<HTMLTableCellElement, TableCellProps>(
  ({ columnDef, onClick, ...props }, ref) => {
    const onCellClick = useCallback(
      (event: MouseEvent<HTMLTableCellElement>) => {
        if (columnDef?.ignoreColumnClick) {
          event.stopPropagation();
          return;
        }

        return onClick?.(event);
      },
      [columnDef?.ignoreColumnClick, onClick],
    );

    return (
      <BaseTableCell
        ref={ref}
        {...props}
        className={cn("h-12 font-normal whitespace-nowrap", props.className)}
        onClick={columnDef?.ignoreColumnClick ? onCellClick : onClick}
      />
    );
  },
);

TableCell.displayName = "TableCell";
