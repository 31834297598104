import { MouseEvent, MouseEventHandler } from "react";

export function onClickWithoutSelection<T>(handler?: MouseEventHandler<T>) {
  if (!handler) {
    return undefined;
  }

  return (event: MouseEvent<T>) => {
    const selection = window.getSelection?.()?.type === "Range";

    if (selection) {
      return event.stopPropagation();
    }

    handler?.(event);
  };
}
