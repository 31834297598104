import { Icon, IconName } from "components/icon/Icon";
import { Input, InputProps } from "components/ui/input";
import { cn } from "utils";
import { KebabCase } from "utils/types";

interface IconInputProps extends InputProps {
  iconName: KebabCase<IconName>;
  value: string;
  className?: string;
  inputClassName?: string;
}

export const IconInput = ({
  iconName,
  className,
  inputClassName,
  ...rest
}: IconInputProps) => (
  <div
    className={cn(
      "flex border border-gray-300 rounded-lg overflow-hidden focus-within:outline focus-within:outline-1 focus-within:outline-primary focus-within:ring focus-within:ring-offset-0 focus-within:ring-primary-100",
      className,
    )}
  >
    <span className="px-3 text-gray-500 border-0 border-r flex items-center">
      <Icon name={iconName} />
    </span>

    <Input
      className={cn(
        "flex-1 rounded-none border-0 focus:outline-none focus:border-0 !shadow-none text-body",
        inputClassName,
      )}
      {...rest}
    />
  </div>
);
