import { type ComponentProps, forwardRef } from "react";
import { TableHead as BaseTableHead } from "components/ui/table";
import { cn } from "utils";

export const TableHead = forwardRef<
  HTMLTableCellElement,
  ComponentProps<typeof BaseTableHead>
>((props, ref) => {
  return (
    <BaseTableHead
      ref={ref}
      {...props}
      className={cn("text-secondary-900", props.className)}
    />
  );
});

TableHead.displayName = "TableHead";
