import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDialog } from "hooks/useDialog";
import { toast } from "utils/toast";
import { Modal } from "components/modal/Modal";
import { api } from "api/client";
import { Icon } from "components/icon/Icon";

interface UndeleteOrganisationModalProps {
  organisationId: string;
  organisationName: string;
}

export const UndeleteOrganisationModal = React.forwardRef<
  HTMLButtonElement,
  UndeleteOrganisationModalProps
>(({ organisationId, organisationName }, ref) => {
  const [isUndeleting, setIsUndeleting] = useState(false);
  const { t } = useTranslation(["settings", "general"]);
  const dialog = useDialog("undelete-organisation");

  async function onConfirm() {
    if (isUndeleting || !organisationId) {
      return;
    }
    setIsUndeleting(true);
    try {
      await api.account.organisations.undeleteOrganisation(organisationId);

      toast.success(
        t("settings:organisations.overview.undelete.success", {
          organisationName,
        }),
      );

      dialog.close();
    } catch (e) {
      toast.error(t("settings:organisations.overview.undelete.error"));
    } finally {
      setIsUndeleting(false);
    }
  }

  return (
    <>
      <button
        ref={ref}
        className="relative cursor-default select-none rounded-sm px-2 py-1.5 text-sm outline-none text-secondary-900 transition-colors focus:bg-secondary-50 data-[disabled]:pointer-events-none data-[disabled]:opacity-50 flex items-center gap-2 w-full justify-start hover:bg-secondary-50"
        type="button"
        onClick={() => dialog.open()}
        aria-label="Undelete Organisation"
        disabled={isUndeleting}
      >
        <Icon name="archive-restore" iconClassName="text-yellow-500" />
        <span className="text-sm text-secondary-500">
          {t("general:buttons.undelete")}
        </span>
      </button>
      <Modal
        id={dialog.id!}
        open={dialog.isOpen}
        mainIconName="octagon-alert"
        variant="warning"
        title={t("settings:organisations.overview.undelete.title")}
        description={t("settings:organisations.overview.undelete.description", {
          organisationName,
        })}
        confirmLabel={t("general:buttons.undelete")}
        confirmIconName="archive-restore"
        cancelLabel={t("general:buttons.cancel")}
        onOpenChange={dialog.onOpenChange}
        loading={isUndeleting}
        onConfirm={() => onConfirm()}
      />
    </>
  );
});

UndeleteOrganisationModal.displayName = "UndeleteOrganisationModal";
