import { type ComponentProps, forwardRef, useMemo } from "react";
import { TableRow as BaseTableRow } from "components/ui/table";
import { cn } from "utils";
import { onClickWithoutSelection } from "utils/events";

export const TableRow = forwardRef<
  HTMLTableRowElement,
  ComponentProps<typeof BaseTableRow>
>(({ className, ...props }, ref) => {
  const onClick = useMemo(
    () => onClickWithoutSelection(props.onClick),
    [props.onClick],
  );

  return (
    <BaseTableRow
      ref={ref}
      {...props}
      onClick={onClick}
      className={cn(
        "text-secondary-600 border-secondary-50 data-[state=selected]:bg-secondary-25 duration-100 hover:bg-initial",
        props.onClick && "hover:bg-secondary-25 cursor-pointer",
        className,
      )}
    />
  );
});

TableRow.displayName = "TableRow";
