import { SkeletonKeyword } from "./SkeletonListItem";

export const ListLoading = () => {
  return (
    <div className="relative min-h-[150px]">
      <SkeletonKeyword className="animate-scale-75" />
      <SkeletonKeyword className="animate-scale-80" />
      <SkeletonKeyword className="animate-scale-85" />
      <SkeletonKeyword className="animate-scale-90" />
      <SkeletonKeyword variant="primary" className="animate-slide-item" />
    </div>
  );
};
