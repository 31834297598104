/* eslint-disable react-hooks/exhaustive-deps */
import { useFormContext } from "react-hook-form";
import { IconInput } from "components/input/IconInput";
import { Calendar } from "components/ui/calendar";
import { ChangeEvent, useEffect, useState } from "react";
import { parse } from "date-fns";
import { DATE_FORMAT } from "hooks/search/constants";
import { useTranslation } from "react-i18next";
import {
  getErrorMessage,
  isValidFilterDate,
  parseDateRange,
  transformToInputDate,
} from "../utils";

export const DateRangeFilter = ({ name }: { name: string }) => {
  const { setValue, watch } = useFormContext();
  const [error, setError] = useState("");
  const dateRange = watch(`filters.${name}.range`) || { from: "", to: "" };
  const { t } = useTranslation(["books"]);

  useEffect(() => {
    setError(getErrorMessage(dateRange, t));
  }, [dateRange]);

  const handleCalendarChange = (
    range: { from?: Date; to?: Date } | undefined,
  ) => {
    if (!range) return;
    const updatedRange = {
      from: range.from ? transformToInputDate(range.from) : undefined,
      to: range.to ? transformToInputDate(range.to) : undefined,
    };
    setValue(`filters.${name}.range`, updatedRange);
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    key: "from" | "to",
  ) => {
    const inputValue = e.target.value;
    if (isValidFilterDate(inputValue)) {
      handleCalendarChange({
        ...dateRange,
        [key]: parse(inputValue, DATE_FORMAT, new Date()),
      });
    } else {
      setValue(`filters.${name}.range`, { ...dateRange, [key]: inputValue });
    }
  };

  return (
    <div className="flex items-center flex-col gap-2 p-2">
      <IconInput
        iconName="calendar-days"
        aria-label="from date input"
        placeholder="DD.MM.YYYY"
        className="w-full"
        value={dateRange?.from || ""}
        onChange={(e) => handleInputChange(e, "from")}
      />
      <IconInput
        iconName="calendar-days"
        className="w-full"
        value={dateRange?.to || ""}
        aria-label="to date input"
        placeholder="DD.MM.YYYY"
        onChange={(e) => handleInputChange(e, "to")}
      />
      {error && <p className="text-red-500 text-xs self-start">{error}</p>}
      <Calendar
        mode="range"
        selected={parseDateRange(dateRange)}
        onSelect={handleCalendarChange}
      />
    </div>
  );
};
