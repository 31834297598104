import { getFile } from "reedy-data/api";
import { FileType } from "reedy-data/models";
import { BaseApi } from "./base";

export class FilesApi extends BaseApi {
  async getFile(fileName: string, fileType: FileType) {
    return this.request(getFile, {
      path: { file_name: fileName, file_type: fileType },
    });
  }
}
