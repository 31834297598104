import { IProduct } from "integrations/firebase/collections";
import { Timestamp } from "firebase/firestore";
import { Checkbox } from "components/ui/checkbox";
import { type ColumnDef } from "components/table";

import { TFunction } from "i18next";
import { TableStatusBadges } from "components/GenerationStatusBadge/TableStatusBadges";
import { InfoTooltip } from "components/InfoTooltip";
import { vectorDistanceToPercentage } from "hooks/search/utils";
import { DataTableColumnHeader } from "./data-table-column-header";

export const columns: (
  t: TFunction,
  options?: {
    showMatch: boolean;
    loading: boolean;
  },
) => ColumnDef<IProduct>[] = (t, options) => [
  {
    id: "select",
    ignoreColumnClick: true,
    header: ({ table }) =>
      options?.loading ? null : (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            (table.getIsSomePageRowsSelected() && "indeterminate")
          }
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
          className="translate-y-[2px]"
        />
      ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
        className="translate-y-[2px]"
      />
    ),
  },
  {
    accessorKey: "isbn",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("books:booktable.columns.isbn")}
      />
    ),
    cell: ({ row }) => <div>{row.getValue("isbn")}</div>,
  },
  {
    accessorKey: "author",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("books:booktable.columns.author")}
      />
    ),
    cell: ({ row }) => {
      const authors = row.getValue("author") as string[];

      return (
        <div className="flex space-x-2">
          <span className="max-w-[500px] truncate">{authors?.join(", ")}</span>
        </div>
      );
    },
  },
  {
    accessorKey: "title",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("books:booktable.columns.title")}
      />
    ),
    cell: ({ row }) => (
      <div className="flex space-x-2">
        <span className="max-w-[500px] truncate">{row.getValue("title")}</span>
      </div>
    ),
  },
  {
    accessorKey: "productGroupDescription",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("books:booktable.columns.productForm")}
      />
    ),
    cell: ({ row }) => (
      <div className="flex items-center">
        <span> {row.getValue("productGroupDescription")}</span>
      </div>
    ),
  },
  {
    accessorKey: "publishedAt",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("books:booktable.columns.published")}
      />
    ),
    cell: ({ row }) => {
      const publishedAt = row.getValue("publishedAt") as Timestamp;
      return (
        <div className="flex items-center">
          <span>
            {publishedAt?.toDate &&
              publishedAt?.toDate()?.toISOString().slice(0, 10)}
          </span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  ...(options?.showMatch
    ? [
        {
          accessorKey: "vector_distance",
          header: ({ column }) => (
            <div className="flex items-center gap-2">
              <DataTableColumnHeader
                column={column}
                title={t("books:booktable.columns.vectorDistance")}
              />
              <InfoTooltip side="top">
                <div className="font-light text-center px-5">
                  <p className="whitespace-pre text-neutral-800">
                    {t("books:booktable.vectorDistanceTooltip.text")}
                  </p>
                  <p className="whitespace-pre text-neutral-400 italic">
                    {t("books:booktable.vectorDistanceTooltip.note")}
                  </p>
                </div>
              </InfoTooltip>
            </div>
          ),
          cell: ({ row }) => {
            const distance = row.getValue("vector_distance") as
              | number
              | undefined;
            return (
              <div className="flex items-center justify-center">
                <span>
                  {distance !== undefined
                    ? `${vectorDistanceToPercentage(distance)}%`
                    : "-"}
                </span>
              </div>
            );
          },
        } as ColumnDef<IProduct>,
      ]
    : []),
  {
    accessorKey: "generated",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("books:booktable.columns.generated")}
      />
    ),
    cell: ({ row }) => {
      return <TableStatusBadges row={row} fetch />;
    },
  },
];
