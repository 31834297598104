import { getUser } from "__generated__/api";
import { BaseApi } from "./base";

export class UsersApi extends BaseApi {
  async get(userId: string) {
    const response = await this.request(
      getUser,
      {
        path: { user_id: userId },
      },
      ({ status }) => status !== 404,
    );

    return response?.data;
  }
}
