import { useMemo } from "react";
import { Row } from "@tanstack/react-table";
import { IProduct } from "integrations/firebase/collections";
import { useProductData } from "hooks/useProductData";
import { getBadgeStatusFromRow } from "./utils";
import { GenerationStatusBadge } from "./GenerationStatusBadge";

interface TableStatusBadgesProps {
  row: Row<IProduct>;
  fetch?: boolean;
}

export const TableStatusBadges = ({ row, fetch }: TableStatusBadgesProps) => {
  const productId = row.original.id;

  const [product = row.original] = useProductData(
    fetch ? productId : undefined,
  );

  const { keywordsStatus, descriptionStatus, subjectsStatus } = useMemo(
    () => getBadgeStatusFromRow(product),
    [product],
  );

  return (
    <div className="flex items-center justify-start gap-x-1">
      <GenerationStatusBadge labelKey="keywords" status={keywordsStatus} />
      <GenerationStatusBadge labelKey="subjects" status={subjectsStatus} />
      <GenerationStatusBadge
        labelKey="description"
        status={descriptionStatus}
      />
    </div>
  );
};
