import {
  collection,
  QueryDocumentSnapshot,
  Timestamp,
} from "firebase/firestore";

import { db } from "integrations/firebase/firestore";
import { FirestoreFile } from "reedy-data/models";

interface FirestoreFileFromFirebase
  extends Omit<FirestoreFile, "createdAt" | "updatedAt"> {
  createdAt: Timestamp | null;
  updatedAt: Timestamp;
}

const fileConverter = {
  toFirestore: (file: FirestoreFile) => file,
  fromFirestore(
    snapshot: QueryDocumentSnapshot<FirestoreFileFromFirebase>,
  ): FirestoreFile & { id: string } {
    const data = snapshot.data();

    return {
      ...data,
      createdAt: data.createdAt?.toDate() || null,
      updatedAt: data.updatedAt.toDate(),
      id: snapshot.id,
    };
  },
};

export const getFilesCollection = () =>
  collection(db, "files").withConverter(fileConverter);
