import * as React from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { DayPicker } from "react-day-picker";
import { buttonVariants } from "components/ui/button";
import { cn } from "utils/cn";

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

const IconLeft = ({
  className,
  ...props
}: React.ComponentProps<typeof ChevronLeft>) => (
  <ChevronLeft className={cn("h-4 w-4", className)} {...props} />
);

const IconRight = ({
  className,
  ...props
}: React.ComponentProps<typeof ChevronRight>) => (
  <ChevronRight className={cn("h-4 w-4", className)} {...props} />
);

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("p-3", className)}
      classNames={{
        months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
        month: "space-y-4",
        caption: "flex justify-center pt-1 relative items-center",
        caption_label: "text-medium font-normal",
        nav: "space-x-1 flex items-center",
        nav_button: cn(
          buttonVariants({ variant: "outline" }),
          "w-6 h-6 bg-transparent p-0 opacity-50 hover:opacity-100",
        ),
        nav_button_previous: "absolute left-1",
        nav_button_next: "absolute right-1",
        table: "w-full border-collapse space-y-1",
        head_row: "flex justify-between",
        head_cell: `rounded-md font-normal text-[0.8rem] w-9 h-9 xl:w-12 xl:h-12
        flex items-center justify-center p-1 m-1 text-sm font-normal text-secondary-400`,
        row: "flex w-full",
        cell: `w-9 h-9 xl:w-12 xl:h-12 text-center relative flex items-center justify-center p-1 m-1 text-sm [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50  first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20`,
        day: cn(
          buttonVariants({ variant: "ghost" }),
          "h-9 w-9 p-0 font-normal aria-selected:opacity-100, rounded-full w-full h-full flex items-center justify-center",
        ),
        day_range_end: "day-range-end",
        day_selected:
          "bg-primary text-white hover:bg-primary hover:text-white focus:text-primary-foreground",
        day_today: "bg-accent text-accent-foreground",
        day_outside:
          "day-outside text-secondary-200 aria-selected:bg-accent/50 aria-selected:text-muted-foreground",
        day_disabled: "text-muted-foreground opacity-50",
        day_range_middle:
          "w-9 aria-selected:bg-accent aria-selected:text-accent-foreground",
        day_hidden: "invisible",
        root: "flex flex-col items-center justify-center",
        ...classNames,
      }}
      components={{
        IconLeft,
        IconRight,
      }}
      {...props}
    />
  );
}
Calendar.displayName = "Calendar";

export { Calendar };
