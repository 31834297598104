import { flexRender } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import {
  TableBody,
  TableHead,
  TableHeader,
  Table,
  TableRow,
} from "components/table";
import { IProject } from "integrations/firebase/collections";
import { useProjects } from "pages/Projects/contexts/ProjectsContext";
import { useTable } from "hooks/useTable";
import { DataTablePagination } from "components/ui/data-table-pagination";
import { columns } from "./columns";
import { PreHeader } from "./PreHeader";
import { ProjectsTableBody } from "./ProjectsTableBody";

interface ProjectsTableProps {
  onRowClicked: (project: IProject) => void;
}

const PAGE_SIZE = 10;
const emptyArray: IProject[] = [];

export const ProjectsTable = ({ onRowClicked }: ProjectsTableProps) => {
  const { projects, isLoadingRead, errorRead, count } = useProjects();
  const { goBackPage, goNextPage, currentPage, goToPage } = useProjects();

  const { t } = useTranslation(["components"]);
  const displayProjects = projects.slice(
    currentPage * PAGE_SIZE,
    currentPage * PAGE_SIZE + PAGE_SIZE,
  );

  const { table } = useTable({
    columns: columns(t),
    data: displayProjects || emptyArray,
    totalRecords: count || 0,
    paginationState: { pagination: { pageIndex: currentPage, pageSize: 10 } },
  });

  return (
    <div className="space-y-4 text-left">
      <PreHeader />
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          <ProjectsTableBody
            isLoadingRead={isLoadingRead}
            errorRead={errorRead}
            projects={projects}
            table={table}
            columns={columns(t)}
            onRowClicked={onRowClicked}
          />
        </TableBody>
      </Table>

      <DataTablePagination
        onNextPage={goNextPage}
        onPreviousPage={goBackPage}
        onSetPage={goToPage}
        table={table}
      />
    </div>
  );
};
