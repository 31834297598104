import {
  SearchFilterComparisonOperator,
  SearchQueryFilters,
  FilterType,
  FilterTabs,
} from "hooks/search/types";

export const defaultFilterValues: SearchQueryFilters = {
  keywordCount: {
    type: FilterType.NUMERIC,
    comparisonOperator: SearchFilterComparisonOperator.GREATER,
    value: undefined,
  },
  publishedAt: {
    tab: FilterTabs.SPECIFIC,
    type: FilterType.DATE_RANGE,
    range: {
      from: "",
      to: "",
    },
  },
  productGroupDescription: {
    values: [],
    type: FilterType.CHECKBOX,
  },
  publisher: {
    values: [],
    type: FilterType.CHECKBOX,
  },
};
