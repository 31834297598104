import React from "react";
import { flexRender, Table } from "@tanstack/react-table";
import { TableRow, TableCell } from "components/table";
import LoadingSpinner from "components/loading/LoadingSpinner";
import { IProject } from "integrations/firebase/collections";
import { useAuth } from "contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "shared/constants";
import { useTranslation } from "react-i18next";
import { getErrorMessage } from "utils/errorParseHelper";
import { EmptyList } from "components/empty/EmptyList";
import { Button } from "components/button";

interface ProjectsTableBodyProps {
  isLoadingRead: boolean;
  errorRead?: Error;
  projects: IProject[];
  table: Table<IProject>;
  columns: any[];
  onRowClicked: (project: IProject) => void;
}

export const ProjectsTableBody: React.FC<ProjectsTableBodyProps> = ({
  isLoadingRead,
  errorRead,
  projects,
  table,
  columns,
  onRowClicked,
}) => {
  const { authUser } = useAuth();
  const { t } = useTranslation(["components"]);
  const navigate = useNavigate();

  const isRowOwner = (project: IProject) => {
    return project.userId === authUser?.uid;
  };

  if (errorRead) {
    return (
      <TableRow>
        <TableCell colSpan={columns.length}>
          <EmptyList
            orientation="horizontal"
            title={t("components:projectsTable.errorProjectList.title")}
            text={t("components:projectsTable.errorProjectList.description")}
            errorMessage={getErrorMessage(errorRead)}
            buttons={[
              <Button
                key="button_reload_after_error"
                label={t("components:projectsTable.errorProjectList.label")}
                icon="refresh-cw"
                onClick={() => window.location.reload()}
                size="small"
                analyticsId="button_reload_after_error"
              />,
            ]}
          />
        </TableCell>
      </TableRow>
    );
  }

  if (isLoadingRead) {
    return (
      <TableRow>
        <TableCell colSpan={columns.length}>
          <div className="flex flex-col items-center justify-center m-8">
            <LoadingSpinner size="80px" />
          </div>
        </TableCell>
      </TableRow>
    );
  }

  if (!projects.length) {
    return (
      <TableRow>
        <TableCell colSpan={columns.length}>
          <EmptyList
            orientation="horizontal"
            title={t("components:projectsTable.emptyProjectList.title")}
            text={t("components:projectsTable.emptyProjectList.description")}
            buttons={[
              <Button
                key="button_search_on_empty_projects_table"
                label={t("components:projectsTable.emptyProjectList.label")}
                icon="loader"
                onClick={() => navigate(RoutePath.Books)}
                size="small"
                analyticsId="button_search_on_empty_projects_table"
              />,
            ]}
          />
        </TableCell>
      </TableRow>
    );
  }

  if (table.getRowModel().rows.length === 0) {
    return (
      <TableRow>
        <TableCell colSpan={columns.length} className="h-24 text-center">
          <p>{t("components:projectsTable.projects.table.noResults")}</p>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      {table.getRowModel().rows.map((row) => (
        <TableRow
          key={row.id}
          data-state={row.getIsSelected() && "selected"}
          onClick={() => onRowClicked(row.original)}
          className="cursor-pointer"
        >
          {row.getVisibleCells().map((cell) => (
            <TableCell key={cell.id} columnDef={cell.column.columnDef}>
              {flexRender(cell.column.columnDef.cell, {
                ...cell.getContext(),
                row: { ...row, isOwner: isRowOwner(row.original) },
              })}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};
