import { TFunction } from "i18next";

export const getStatusCodeDescription = (statusCode: string, t: TFunction) => {
  // "01": "Cancelled",
  // "09": "Not_yet_available,_postponed_indefinitely",
  // "10": "Not_yet_available",
  // "11": "Awaiting_stock",
  // "12": "Not_yet_available,_will_be_POD",
  // "20": "Available",
  // "21": "In_stock",
  // "22": "To_order",
  // "23": "POD",
  // "30": "Temporarily_unavailable",
  // "31": "Out_of_stock",
  // "32": "Reprinting",
  // "33": "Awaiting_reissue",
  // "34": "Temporarily_withdrawn_from_sale",
  // "40": "Not_available_(reason_unspecified)",
  // "41": "Not_available,_replaced_by_new_product",
  // "42": "Not_available,_other_format_available",
  // "43": "No_longer_supplied_by_the_supplier",
  // "44": "Apply_direct",
  // "45": "Not_sold_separately",
  // "46": "Withdrawn_from_sale",
  // "47": "Remaindered",
  // "48": "Not_available,_replaced_by_POD",
  // "49": "Recalled",
  // "50": "Not_sold_as_set",
  // "51": "Not_available,_publisher_indicates_OP",
  // "52": "Not_available,_publisher_no_longer_sells_product_in_this_market",
  // "97": "No_recent_update_received",
  // "98": "No_longer_receiving_updates",
  // "99": "Contact_supplier",
  switch (statusCode) {
    case "01":
      return t("settings:organisation.productAvailability.codes.01");
    case "09":
      return t("settings:organisation.productAvailability.codes.09");
    case "10":
      return t("settings:organisation.productAvailability.codes.10");
    case "11":
      return t("settings:organisation.productAvailability.codes.11");
    case "12":
      return t("settings:organisation.productAvailability.codes.12");
    case "20":
      return t("settings:organisation.productAvailability.codes.20");
    case "21":
      return t("settings:organisation.productAvailability.codes.21");
    case "22":
      return t("settings:organisation.productAvailability.codes.22");
    case "23":
      return t("settings:organisation.productAvailability.codes.23");
    case "30":
      return t("settings:organisation.productAvailability.codes.30");
    case "31":
      return t("settings:organisation.productAvailability.codes.31");
    case "32":
      return t("settings:organisation.productAvailability.codes.32");
    case "33":
      return t("settings:organisation.productAvailability.codes.33");
    case "34":
      return t("settings:organisation.productAvailability.codes.34");
    case "40":
      return t("settings:organisation.productAvailability.codes.40");
    case "41":
      return t("settings:organisation.productAvailability.codes.41");
    case "42":
      return t("settings:organisation.productAvailability.codes.42");
    case "43":
      return t("settings:organisation.productAvailability.codes.43");
    case "44":
      return t("settings:organisation.productAvailability.codes.44");
    case "45":
      return t("settings:organisation.productAvailability.codes.45");
    case "46":
      return t("settings:organisation.productAvailability.codes.46");
    case "47":
      return t("settings:organisation.productAvailability.codes.47");
    case "48":
      return t("settings:organisation.productAvailability.codes.48");
    case "49":
      return t("settings:organisation.productAvailability.codes.49");
    case "50":
      return t("settings:organisation.productAvailability.codes.50");
    case "51":
      return t("settings:organisation.productAvailability.codes.51");
    case "52":
      return t("settings:organisation.productAvailability.codes.52");
    case "97":
      return t("settings:organisation.productAvailability.codes.97");
    case "98":
      return t("settings:organisation.productAvailability.codes.98");
    case "99":
      return t("settings:organisation.productAvailability.codes.99");
    default:
      return t("settings:organisation.productAvailability.codes.default");
  }
};
