import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useApi } from "../../hooks/useApi";
import { ProfilePicture } from "./ProfilePicture";
import { Skeleton } from "../ui/skeleton";

interface UserProfileProps {
  userId: string;
}

export function UserProfile({ userId }: UserProfileProps) {
  const api = useApi();
  const { t } = useTranslation("profile");

  const {
    data: user,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["users", userId],
    queryFn: async ({ queryKey: [, id] }) => {
      const profile = await api.users.get(id);

      if (!profile) {
        return null;
      }

      return profile;
    },
    staleTime: Infinity,
    retry: false,
  });

  const displayName = useMemo(() => {
    if (isLoading || isError) {
      return "";
    }

    if (user === null) {
      return t("profile:deleted");
    }

    if (!user) {
      return t("profile:failed");
    }

    return `${user.firstName} ${user.lastName}`;
  }, [user, isLoading, isError, t]);

  return (
    <div className="flex items-center gap-2">
      <ProfilePicture
        name={displayName}
        photoUrl={user?.photoURL}
        className="h-8 w-8"
      />

      <span className="min-w-[100px]">
        {isLoading ? (
          <Skeleton className="w-[100px] h-4" />
        ) : (
          <span>{isError ? t("profile:failed") : displayName}</span>
        )}
      </span>
    </div>
  );
}
