import { BasePanel } from "components/BasePanel";
import { EmptyList } from "components/empty/EmptyList";
import { ListLoading } from "components/skeletons/ListLoading";
import { useTranslation } from "react-i18next";

export const KeywordsGenerating = () => {
  const { t } = useTranslation(["productDetails"]);

  return (
    <BasePanel
      title={t("productDetails:generatedKeywords.header")}
      isEmpty
      emptyComponent={
        <EmptyList
          title={t("productDetails:generatedKeywords.loading")}
          image={
            <div className="translate-y-16 -translate-x-[7.2rem]">
              <ListLoading />
            </div>
          }
        />
      }
    />
  );
};
