import { useProduct } from "contexts/ProductContext";
import { useTranslation } from "react-i18next";
import { BasePanel } from "components/BasePanel";
import { FirestoreProductDescription } from "__generated__/models";
import { useBookDescription } from "contexts/BookDescriptionContext";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { getDefaultDescription } from "integrations/firebase/collections";
import { filterDescriptions } from "shared/constants";
import { EmptyList } from "components/empty/EmptyList";

type GroupedDescriptions = {
  [textType: string]: FirestoreProductDescription[];
};

export const DescriptionsList = () => {
  const { t } = useTranslation(["productDetails"]);
  const { product } = useProduct();
  const { selectedDescriptionHash, setSelectedDescriptionHash } =
    useBookDescription();
  const [searchParams, setSearchParams] = useSearchParams();

  // Read description hash from URL on mount and when URL changes
  useEffect(() => {
    const hashFromUrl = searchParams.get("descriptionHash");
    const defaultDescription = getDefaultDescription(product);
    const productHasDescriptionWithHash = product?.descriptions?.some(
      (desc) => desc.hash === hashFromUrl,
    );

    // If there's a hash in the URL and it exists in the product descriptions, use it
    if (hashFromUrl && productHasDescriptionWithHash) {
      if (hashFromUrl !== selectedDescriptionHash) {
        setSelectedDescriptionHash(hashFromUrl);
      }
    } else if (defaultDescription?.hash) {
      // Otherwise use the default description if available
      setSelectedDescriptionHash(defaultDescription.hash);
      searchParams.set("descriptionHash", defaultDescription.hash);
      setSearchParams(searchParams, { replace: true });
    }
  }, [
    searchParams,
    setSelectedDescriptionHash,
    selectedDescriptionHash,
    product,
    setSearchParams,
  ]);

  // Update URL when description hash changes
  const handleDescriptionClick = (hash: string) => {
    if (hash) {
      setSelectedDescriptionHash(hash);
      searchParams.set("descriptionHash", hash);
      setSearchParams(searchParams, { replace: true });
    }
  };

  const filteredDescriptions = filterDescriptions(product?.descriptions ?? []);

  // Group descriptions by textType
  const groupedDescriptions = filteredDescriptions.reduce<GroupedDescriptions>(
    (acc, desc) => {
      const textType =
        desc.textType || t("productDetails:bookDescription.defaultTextType");
      if (!acc[textType]) {
        acc[textType] = [];
      }
      acc[textType].push(desc);
      return acc;
    },
    {},
  );

  return (
    <BasePanel
      title={t("productDetails:bookDescription.textTypes")}
      isEmpty={!product?.descriptions?.length}
      emptyComponent={
        <EmptyList
          title={t("productDetails:bookDescription.noTextTypes")}
          text={t("productDetails:bookDescription.noTextTypesDescription")}
          variant="primary"
        />
      }
      className="h-full"
      border={false}
    >
      <div>
        <div className="space-y-6">
          {Object.entries(groupedDescriptions || {}).map(
            ([textType, descriptions]) => (
              <div key={textType} className="space-y-2">
                <h3 className="text-md text-secondary-800 pl-2 pt-4">
                  {textType}
                </h3>
                <div className=" space-y-2">
                  {descriptions.map((description) => (
                    <button
                      type="button"
                      key={description.hash}
                      onClick={() =>
                        description.hash &&
                        handleDescriptionClick(description.hash)
                      }
                      className={`flex items-center space-x-2 text-md text-secondary-800 transition-colors w-full text-left p-2 ${
                        selectedDescriptionHash === description.hash
                          ? "border-l-2 border-primary-500 bg-secondary-50"
                          : "border-l-2 border-transparent"
                      }`}
                    >
                      <span className="ml-6">
                        {description.audiences?.join(", ") || "-"}
                      </span>
                    </button>
                  ))}
                </div>
              </div>
            ),
          )}
        </div>
      </div>
    </BasePanel>
  );
};
