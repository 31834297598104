import { type ComponentProps, forwardRef } from "react";
import { Table as BaseTable } from "components/ui/table";
import { cn } from "utils";

interface TableProps extends ComponentProps<typeof BaseTable> {
  variant?: "bordered" | "shadowed";
}

export const Table = forwardRef<HTMLTableElement, TableProps>(
  ({ variant = "bordered", ...props }, ref) => {
    return (
      <div
        className={cn(
          "relative w-full overflow-hidden rounded-xl",
          variant === "bordered" && "border border-secondary-200",
          variant === "shadowed" && "shadow-table",
        )}
      >
        <BaseTable ref={ref} {...props} />
      </div>
    );
  },
);

Table.displayName = "Table";
