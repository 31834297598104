import { useTranslation } from "react-i18next";
import { useDialog } from "hooks/useDialog";
import { Button } from "components/button";
import { BasePanel } from "components/BasePanel";
import { EmptyList } from "components/empty/EmptyList";
import { KeywordItem } from "../KeywordItem";
import { RegenerateKeywordsDialog } from "./RegenerateKeywordsDialog";
import { useProduct } from "../../../../../../contexts/ProductContext";
import { getKeywordsByType } from "../../../../../../integrations/firebase/collections";

interface GeneratedKeywordsProps {
  onGenerate: () => void;
}

export const GeneratedKeywords = ({ onGenerate }: GeneratedKeywordsProps) => {
  const { t } = useTranslation(["productDetails", "general"]);
  const { product } = useProduct();

  const { generated: keywords, final: finalKeywords } =
    getKeywordsByType(product);
  const isEmptyList = keywords.length === 0;

  const { onOpenChange, isOpen, onOpen } = useDialog("regenerate-keywords");

  const removedDuplicateKeywords = keywords?.filter(
    (keyword, index, self) =>
      keyword.id && self.findIndex((k) => k.id === keyword.id) === index,
  );

  return (
    <>
      <BasePanel
        title={t("productDetails:generatedKeywords.header")}
        isEmpty={isEmptyList}
        emptyComponent={
          <EmptyList
            title={t("productDetails:noKeywords.title")}
            text={t("productDetails:noKeywords.description")}
            buttons={[
              <Button
                key="generate"
                label={t("general:buttons.generate")}
                onClick={onGenerate}
                icon="loader"
                size="small"
                analyticsId="button_regenerate_keywords"
              />,
            ]}
          />
        }
        headerAction={
          !isEmptyList && (
            <Button
              onClick={onOpen}
              label={t("general:buttons.regenerate")}
              icon="loader"
              variant="link"
              size="small"
              className="text-primary-500"
            />
          )
        }
      >
        {removedDuplicateKeywords?.map((item) => (
          <KeywordItem
            key={item.id}
            keyword={item}
            testId={`add-${item.id}-button`}
            isSelected={finalKeywords.map((k) => k.id).includes(item.id)}
          />
        ))}
      </BasePanel>
      <RegenerateKeywordsDialog onOpenChange={onOpenChange} isOpen={isOpen} />
    </>
  );
};
