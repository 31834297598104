import { useTranslation } from "react-i18next";
import { useFeatureFlags } from "contexts/FeatureFlagContext";
import { HomeHeader } from "./components/HomeHeader";
import { HomeSearch } from "./components/HomeSearch";
import { BacklistLinks } from "./components/BacklistLinks";
import { EvaluationResults } from "./components/EvaluationResults";
import { RecentProjects } from "./components/RecentProjects/RecentProjects";
import { UploadManuscript } from "./components/UploadManuscript";

export const HomePage = () => {
  const { t } = useTranslation(["home"]);
  const { features } = useFeatureFlags();

  return (
    <div>
      <div className="-mx-16 -mt-8 mb-8 bg-secondary-25 flex flex-col justify-center items-center gap-8 py-14 px-10">
        <HomeHeader />
        <HomeSearch />

        <div className="text-secondary-400 text-center">
          {t("home:search.description")}
        </div>
      </div>

      <div className="w-full bg-white space-y-10">
        <BacklistLinks />

        <RecentProjects />
        {features.uploadManuscript && <UploadManuscript />}
        <EvaluationResults />
      </div>
    </div>
  );
};
