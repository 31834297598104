import { cn } from "utils/cn";

interface EmptyPanelProps {
  image: React.ReactNode;
  title: string;
  text?: string;
  errorMessage?: string;
  buttons?: React.ReactNode[];
  orientation?: "vertical" | "horizontal";
  hideOnShortScreen?: boolean;
}

export const EmptyPanel = ({
  image,
  title,
  text,
  errorMessage,
  buttons,
  orientation = "vertical",
  hideOnShortScreen = false,
}: EmptyPanelProps) => {
  const leftAligned = "items-start justify-start text-left";
  const centerAligned = "items-center justify-center text-center";
  const aligned = orientation === "horizontal" ? leftAligned : centerAligned;
  const titleStyle = orientation === "horizontal" ? "font-semibold" : undefined;
  const textStyle =
    orientation === "horizontal" ? "text-secondary-400" : undefined;
  const flexDirection = orientation === "horizontal" ? "flex-row" : "flex-col";
  const hideOnShortScreenStyle = hideOnShortScreen
    ? "hide-on-short-screen"
    : undefined;

  return (
    <div
      className={cn(
        "flex flex-col items-center justify-center space-y-6 py-8",
        flexDirection,
      )}
    >
      <div className={hideOnShortScreenStyle}>{image}</div>
      <div className={cn("flex flex-col justify-center", aligned)}>
        <div className={cn("text-center space-y-2 px-4", aligned)}>
          <p className={cn("text-secondary-900", titleStyle)}>{title}</p>
          {text && (
            <p className={cn("text-sm text-secondary-500", textStyle)}>
              {text}
            </p>
          )}
          {errorMessage && (
            <p className="text-sm font-normal text-red-400">{errorMessage}</p>
          )}
          {buttons && (
            <div className={cn("flex gap-2", aligned)}>{buttons}</div>
          )}
        </div>
      </div>
    </div>
  );
};
