import { cva } from "class-variance-authority";
import { Icon } from "components/icon/Icon";
import { Skeleton } from "components/ui/skeleton";
import { cn } from "utils";

export type SkeletonKeywordVariant = "secondary" | "primary" | "dark" | "error";

interface SkeletonKeywordProps {
  variant?: SkeletonKeywordVariant;
  className?: string;
  animated?: boolean;
}

const skeletonKeywordStyles = cva(
  "absolute transition-colors border-[1.15px] rounded-md p-4 bg-white flex items-center space-x-6",
  {
    variants: {
      variant: {
        secondary: "border-secondary-200",
        primary: "border-primary-300 shadow-[0px_6px_12px_0px_#528BFF14]",
        dark: "border-secondary-400",
        error: "border-system-error shadow-[0px_6px_12px_0px_#DC262614]",
      },
    },
  },
);

export const SkeletonKeyword = ({
  variant = "secondary",
  className,
  animated = true,
}: SkeletonKeywordProps) => {
  return (
    <div className={cn(skeletonKeywordStyles({ variant }), className)}>
      <Skeleton animated={animated} className="h-4 w-40 bg-secondary-100" />
      <span className="text-secondary-200">
        <Icon className="animate-pulse" name="circle-plus" size="small" />
      </span>
    </div>
  );
};
