export type Country = {
  country_code: string;
  country_name: string;
};
export const countries: Country[] = [
  {
    country_code: "AR",
    country_name: "Argentina",
  },
  {
    country_code: "AU",
    country_name: "Australia",
  },
  {
    country_code: "AT",
    country_name: "Austria",
  },
  {
    country_code: "BE",
    country_name: "Belgium",
  },
  {
    country_code: "BR",
    country_name: "Brazil",
  },
  {
    country_code: "CA",
    country_name: "Canada",
  },
  {
    country_code: "CL",
    country_name: "Chile",
  },
  {
    country_code: "CO",
    country_name: "Colombia",
  },
  {
    country_code: "CZ",
    country_name: "Czech Republic",
  },
  {
    country_code: "DK",
    country_name: "Denmark",
  },
  {
    country_code: "EG",
    country_name: "Egypt",
  },
  {
    country_code: "FI",
    country_name: "Finland",
  },
  {
    country_code: "FR",
    country_name: "France",
  },
  {
    country_code: "DE",
    country_name: "Germany",
  },
  {
    country_code: "HU",
    country_name: "Hungary",
  },
  {
    country_code: "IN",
    country_name: "India",
  },
  {
    country_code: "ID",
    country_name: "Indonesia",
  },
  {
    country_code: "IL",
    country_name: "Israel",
  },
  {
    country_code: "IT",
    country_name: "Italy",
  },
  {
    country_code: "JP",
    country_name: "Japan",
  },
  {
    country_code: "MY",
    country_name: "Malaysia",
  },
  {
    country_code: "MX",
    country_name: "Mexico",
  },
  {
    country_code: "NL",
    country_name: "Netherlands",
  },
  {
    country_code: "NZ",
    country_name: "New Zealand",
  },
  {
    country_code: "NG",
    country_name: "Nigeria",
  },
  {
    country_code: "NO",
    country_name: "Norway",
  },
  {
    country_code: "PH",
    country_name: "Philippines",
  },
  {
    country_code: "PL",
    country_name: "Poland",
  },
  {
    country_code: "PT",
    country_name: "Portugal",
  },
  {
    country_code: "RO",
    country_name: "Romania",
  },
  {
    country_code: "SA",
    country_name: "Saudi Arabia",
  },
  {
    country_code: "ZA",
    country_name: "South Africa",
  },
  {
    country_code: "KR",
    country_name: "South Korea",
  },
  {
    country_code: "SE",
    country_name: "Sweden",
  },
  {
    country_code: "CH",
    country_name: "Switzerland",
  },
  {
    country_code: "TW",
    country_name: "Taiwan",
  },
  {
    country_code: "TH",
    country_name: "Thailand",
  },
  {
    country_code: "TR",
    country_name: "Turkey",
  },
  {
    country_code: "UA",
    country_name: "Ukraine",
  },
  {
    country_code: "GB",
    country_name: "United Kingdom",
  },
  {
    country_code: "US",
    country_name: "United States",
  },
  {
    country_code: "VN",
    country_name: "Vietnam",
  },
];
