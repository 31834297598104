import { type ColumnDef } from "components/table";
import { IUser } from "integrations/firebase/collections";
import { TFunction } from "i18next";
import { DataTableColumnHeader } from "components/ui/data-table-column-header";
import { ProfilePicture } from "components/user/ProfilePicture";
import { UserRole } from "__generated__/api";
import { DeleteOrganisationMemberModal } from "./DeleteOrganisationMemberModal";
import { UpdateOrganisationMemberUserRole } from "./UpdateOrganisationMemberRoleModal";

interface OrganisationMembersTableColumnsProps {
  showAdminRequiredModal(): void;
}

export const columns: (
  t: TFunction,
  { showAdminRequiredModal }: OrganisationMembersTableColumnsProps,
) => ColumnDef<IUser>[] = (t: TFunction, { showAdminRequiredModal }) => [
  {
    accessorKey: "email",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("settings:organisation.members.table.columns.email")}
      />
    ),
    cell: ({ row }) => (
      <div className="flex space-x-4 items-center">
        <ProfilePicture
          photoUrl={row.original.photoUrl}
          name={row.original.givenName ?? row.original.email}
        />

        <div>{row.getValue("email")}</div>
      </div>
    ),
  },
  {
    id: "permissions",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("settings:organisation.members.table.columns.role")}
      />
    ),
    cell: ({ row }) => {
      const [role = UserRole.MEMBER] = row.original.roles ?? [];

      return (
        <div className="flex space-x-4 items-center">
          <UpdateOrganisationMemberUserRole
            userId={row.original.userId}
            email={row.original.email}
            role={role}
            showAdminRequiredModal={showAdminRequiredModal}
          />
        </div>
      );
    },
  },
  {
    id: "actions",
    cell: ({ row }) => (
      <div className="flex space-x-4 items-center">
        <DeleteOrganisationMemberModal
          userId={row.original.userId}
          email={row.original.email}
          showAdminRequiredModal={showAdminRequiredModal}
        />
      </div>
    ),
  },
];
