import * as React from "react";
import { cn } from "utils";

export interface BasePanelProps {
  title: string;
  headerClassName?: string;
  contentClassName?: string;
  className?: string;
  headerAction?: React.ReactNode;
  footer?: React.ReactNode;
  isEmpty?: boolean;
  emptyComponent?: React.ReactNode;
  children?: React.ReactNode;
  border?: boolean;
  wrapper?: boolean;
  ref?: React.Ref<HTMLDivElement>;
}

export const BasePanel = ({
  title,
  headerClassName,
  contentClassName,
  className,
  headerAction,
  footer,
  isEmpty,
  emptyComponent,
  children,
  border = true,
  wrapper = true,
  ref,
}: BasePanelProps) => {
  const noBorder = !border && "border-none rounded-none";

  return (
    <div
      ref={ref}
      className={cn(
        "flex flex-col w-full rounded-xl overflow-hidden border border-secondary-200",
        noBorder,
        className,
      )}
    >
      <div
        className={cn(
          "flex flex-shrink-0 items-center justify-between h-12 px-5 bg-secondary-50",
          headerClassName,
        )}
      >
        <p className="font-medium text-sm">{title}</p>
        {headerAction}
      </div>

      {isEmpty ? (
        <div className={cn("flex-1 overflow-y-auto", contentClassName)}>
          {emptyComponent}
        </div>
      ) : null}

      {!isEmpty && !wrapper ? children : null}

      {!isEmpty && wrapper ? (
        <div className={cn("flex-1 overflow-y-auto", contentClassName)}>
          <div className="grid grid-cols-1">{children}</div>
        </div>
      ) : null}

      {footer}
    </div>
  );
};
