import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Switch } from "components/ui/switch";
import { ArrowLeft } from "lucide-react";
import {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  Table,
  TableRow,
} from "components/table";
import { useOrganisation } from "contexts/OrganisationProvider";
import { SharedDataSchema } from "__generated__/models";
import { toast } from "utils/toast";
import { getStatusCodeDescription } from "./statusCodeMappings";

export function ManageProductAvailabilityPage() {
  const { t } = useTranslation(["settings", "general"]);
  const { organisation, updateOrganisationSettings } = useOrganisation();

  const supplyStatusCodesOrdered = Object.entries(
    SharedDataSchema.properties.productAvailabilities.example,
  ).sort(([, code], [, code2]) => code.localeCompare(code2));
  const productAvailabilities = Object.fromEntries(supplyStatusCodesOrdered);

  const inactiveProductStatus =
    organisation?.settings?.inactiveProductStatus || [];

  const isStatusActive = (code: string) =>
    !inactiveProductStatus.includes(code);

  const handleToggleStatus = async (code: string) => {
    let newinactiveProductStatus: string[];

    if (isStatusActive(code)) {
      newinactiveProductStatus = [...inactiveProductStatus, code];
    } else {
      newinactiveProductStatus = inactiveProductStatus.filter(
        (status: string) => status !== code,
      );
    }

    try {
      await updateOrganisationSettings(newinactiveProductStatus);
      toast.success(t("organisation.productAvailability.updateSuccess"));
    } catch (error) {
      toast.error(
        t("organisation.productAvailability.updateError", {
          message: (error as Error).message,
        }),
      );
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center text-primary-500">
        <Link to="../settings" className="flex items-center gap-2 text-sm">
          <ArrowLeft className="h-4 w-4 " />
          {t("organisation.productAvailability.back")}
        </Link>
      </div>

      <div>
        <h2 className="text-2xl font-semibold mb-2">
          {t("organisation.productAvailability.title")}
        </h2>
        <p className="text-sm text-secondary-400">
          {t("organisation.productAvailability.description")}
        </p>
      </div>

      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>{t("organisation.productAvailability.code")}</TableHead>
            <TableHead>
              {t("organisation.productAvailability.heading")}
            </TableHead>
            <TableHead className="text-right">
              <div className="flex items-center justify-end gap-2">
                {t("organisation.productAvailability.active")}
              </div>
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {Object.entries(productAvailabilities).map(([value, code]) => (
            <TableRow key={code}>
              <TableCell>{code}</TableCell>
              <TableCell>{getStatusCodeDescription(code, t)}</TableCell>
              <TableCell className="text-right">
                <Switch
                  checked={isStatusActive(value)}
                  onCheckedChange={() => handleToggleStatus(value)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
