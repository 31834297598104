/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Checkbox } from "components/ui/checkbox";
import { FormField, FormItem, FormMessage } from "components/ui/form";
import { Label } from "components/ui/label";
import { useFormContext } from "react-hook-form";

export const CheckboxSection = ({
  items,
  name,
  label,
  noSuggestionsFound,
}: {
  items: string[];
  name: string;
  label: string;
  noSuggestionsFound?: string;
}) => {
  const { control } = useFormContext();

  if (noSuggestionsFound) {
    return (
      <div className="flex flex-col gap-y-2 p-2">
        <Label className="text-sm text-secondary-500 font-normal">
          {label}
        </Label>
        <div className="flex w-full justify-center text-sm py-2 px-6 text-foreground">
          {noSuggestionsFound}
        </div>
      </div>
    );
  }

  if (!items || items.length === 0) return null;

  return (
    <div className="flex flex-col gap-y-2 p-2">
      <Label className="text-sm text-secondary-500 font-normal">{label}</Label>
      <FormField
        control={control}
        name={`filters.${name}.values`}
        render={({ field }) => {
          return (
            <FormItem>
              {items.map((item) => {
                const isChecked = field.value?.includes(item);
                return (
                  <label
                    htmlFor={`checkbox-filter-${item}`}
                    key={item}
                    onMouseDown={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                    }}
                    data-testid={`checkbox-filter-${item}`}
                    className="flex flex-row items-center w-full space-x-3 space-y-0 p-1 mt-0 hover:bg-gray-100 rounded-md cursor-pointer text-sm"
                    onClick={() => {
                      field.onChange(
                        isChecked
                          ? field.value?.filter(
                              (value: string) => value !== item,
                            )
                          : [...field.value, item],
                      );
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter" || event.key === " ") {
                        event.preventDefault();
                        field.onChange(
                          isChecked
                            ? field.value?.filter(
                                (value: string) => value !== item,
                              )
                            : [...field.value, item],
                        );
                      }
                    }}
                  >
                    <Checkbox
                      checked={isChecked}
                      className="ml-1 mr-3"
                      id={`checkbox-filter-${item}`}
                      onFocus={(event) => event.target.blur()}
                      onMouseDown={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                      }}
                    />
                    {item}
                  </label>
                );
              })}
              <FormMessage />
            </FormItem>
          );
        }}
      />
    </div>
  );
};
