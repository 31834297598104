import { Client } from "@hey-api/client-fetch";
import { parseResult } from "../parseHelper";

export class BaseApi {
  constructor(public client: Client) {}

  async request<
    Requester extends (options: any) => Promise<any>,
    O extends Parameters<Requester>[0],
    R = Awaited<ReturnType<Requester>>,
    Data = R extends { data: infer D } ? D : never,
  >(
    requester: Requester,
    options: O,
    throwOnError: boolean | ((response: Response) => boolean) = true,
  ): Promise<Data | undefined> {
    const response = await requester({
      throwOnError: false,
      client: this.client,
      ...options,
    } as O);

    return parseResult(response, options, throwOnError);
  }
}
