import { Button } from "components/button";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { Badge } from "components/ui/badge";
import { useFilters } from "hooks/search/useFilters";
import { getNoOfValidFilters } from "./utils";
import { defaultFilterValues } from "./constants";
import { FilterItem } from "./FilterItem";

export function SearchFilters() {
  const { t } = useTranslation(["books", "general"]);
  const { watch, setValue } = useFormContext();
  const { filters } = useFilters();

  const activeFilters = getNoOfValidFilters(watch("filters"));

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          className="h-10"
          variant="tertiary"
          icon="list-filter"
          aria-label={t("books:filters.label")}
        >
          <p className="leading-none text-center">{t("books:filters.label")}</p>
          {activeFilters > 0 && (
            <Badge
              data-testid="active-filters-badge"
              variant="error"
              className="ml-2 px-0 min-w-4 w-4 h-4"
            >
              {activeFilters}
            </Badge>
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="rounded-xl" align="start">
        <DropdownMenuGroup>
          {filters.map(
            (filter) =>
              filter.show && <FilterItem key={filter.name} {...filter} />,
          )}
        </DropdownMenuGroup>
        <span className="flex flex-row w-full justify-between gap-2 m-1 border-t border-gray-200 pt-2">
          <Button
            variant="tertiary"
            size="small"
            label={t("general:buttons.clearAll")}
            analyticsId="button_clear_filter"
            onClick={() => setValue("filters", defaultFilterValues)}
          />
        </span>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
